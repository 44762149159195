import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { setAll } from "../../helpers";
import { RootState } from "../store";

export const loadCurrentpage = createAsyncThunk(
  "app/loadCurrentpage",
  (currentpage: string): { currentpage: string } => ({
    currentpage: currentpage,
  })
);

const initialState = {
  currentpage: "Homepage",
};

export interface IAppSlice {
  currentpage: string;
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadCurrentpage.fulfilled, (state, action) => {
        setAll(state, action.payload);
      })
      .addCase(HYDRATE, (state, action) => state);
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const getAppState = createSelector(baseInfo, (app) => app);
