import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
  ReactElement,
} from "react";
import {
  useSignMessage,
  useConnect,
  useAccount,
  useNetwork,
  useDisconnect,
  useSwitchNetwork,
  useWalletClient,
} from "wagmi";
import "wagmi/window";
import { SiweMessage } from "siwe";
import { ConnectArgs } from "@wagmi/core";
import { LedgerConnector } from "wagmi/connectors/ledger";
import { ethers, utils } from "ethers";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { defaultChains } from "../../lib/wagmi";
import { LedgerQuestContract } from "../../abi";
import { useEthersProvider } from "../../lib/adapters/ethers";
import logger from "../../helpers/logger";
import formatServerUrl from "../../helpers/formatServerUrl";

declare global {
  interface Navigator {
    brave: {
      isBrave(): boolean;
    };
  }
}

export const availableProviders = {
  MetaMask: "metaMask",
  LedgerConnect: "ledger",
  Coinbase: "coinbaseWallet",
  Injected: "injected",
};

type onChainProvider = {
  login: (string, boolean) => void;
  retry: () => void;
  reset: () => void;
  logout: () => void;
  isReadyToQuest: boolean;
  isOnWrongNetwork: boolean;
  isProcessing: boolean;
  isSignedIn: boolean;
  isSigning: boolean;
  isConnectError: string | null;
  isLoading: boolean;
  userCompletedQuestsAsynchronous: number[];
  addCompletedQuest: (number) => void;
};

export type LoginContextData = {
  onChainProvider: onChainProvider;
} | null;

const LoginContext = React.createContext<LoginContextData>(null);

export const useLoginContext = () => {
  const loginContext = useContext(LoginContext);
  if (!loginContext) {
    throw new Error(
      "useLoginContext() can only be used inside of <useLoginContextProvider />, " +
        "please `declare` it at a higher level."
    );
  }
  const { onChainProvider } = loginContext;
  return useMemo(() => ({ ...onChainProvider }), [loginContext]);
};

export const LoginContextProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  let compLoading = false;
  let logoutOnRefresh = true;
  const { t } = useTranslation("common");
  const provider = useEthersProvider();
  const router = useRouter();

  const envChainId = Number(process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_CHAINID);
  const [lastSelectedProvider, setLastSelectedProvider] = useState<string>("");
  const [isReadyToQuest, setIsReadyToQuest] = useState<boolean>(false);
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [isOnWrongNetwork, setIsOnWrongNetwork] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isSigning, setIsSigning] = useState<boolean>(false);
  const [signedAddress, setSignedAddress] = useState<string>("");
  const [isConnectError, setIsConnectError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isGetUserLoading, setIsGetUserLoading] = useState<boolean>(false);
  const [isReadyLoading, setIsReadyLoading] = useState<boolean>(false);
  const [userCompletedQuestsAsynchronous, setUserCompletedQuestsAsynchronous] =
    useState<number[]>([]);
  const [redirectToProfile, setRedirectToProfile] = useState<boolean>(false);

  const { connectors } = useConnect();
  const {
    connect,
    isLoading: connectIsLoading,
    reset: connectReset,
  } = useConnect({
    async onError(err) {
      setIsSigning(true);
      setIsConnectError(null);
      if (err.message === "Connector already connected") {
        await tryToSignIn();
      } else {
        setIsSigning(false);
        if (err.message === "Resource unavailable")
          setIsConnectError(t("error.unavailableResource"));
        else if (err.message === "User rejected request")
          setIsConnectError(t("error.signatureRejected"));
        else {
          setIsConnectError(t("error.globalErrorMessage"));
        }
      }
    },
    onSuccess() {
      setIsSigning(true);
    },
  });
  const { address, isConnected } = useAccount();
  const { data: signer } = useWalletClient();
  const { disconnectAsync } = useDisconnect();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork({
    onSuccess: async (newChain) => {
      if (newChain.id === envChainId) {
        setIsConnectError(null);
        await signIn();
      }
    },
    onError(err) {
      if (err.message === "User rejected request")
        setIsConnectError(t("error.signatureRejected"));
      else if (err.message === "Error switching chain")
        setIsConnectError(t("error.chainSwitchError"));
      else if (err.message === "Connector not found")
        setIsConnectError(t("error.connectorNotFound"));
      else {
        setIsConnectError(t("error.switchingNetworkError"));
      }
    },
    throwForSwitchChainNotSupported: true,
  });

  useEffect(() => {
    setIsLoading(isGetUserLoading || isReadyLoading);
  }, [isGetUserLoading, isReadyLoading]);

  const signOutsignIn = async () => {
    await signOut();
    await signIn();
  };

  useEffect(() => {
    if (address && signedAddress !== "" && address !== signedAddress) {
      signOutsignIn();
    }
  }, [address, signedAddress]);

  const {
    signMessageAsync,
    isLoading: signIsLoading,
    reset: signReset,
  } = useSignMessage({
    onSuccess() {
      setIsConnectError(null);
      setIsSigning(true);
    },
    onError(err) {
      setIsSigning(false);
      if (err.message.includes("User denied signature")) {
        setIsConnectError(t("error.signatureRejected"));
      } else {
        setIsConnectError(t("error.globalErrorMessage"));
      }
    },
  });

  const signOut = async () => {
    const serverUrl = formatServerUrl("/api/logout");
    await fetch(serverUrl, {
      method: "POST",
      body: "logout",
    });
    setSignedAddress("");
    setIsSigning(false);
  };

  useEffect(() => {
    if (isReadyToQuest) {
      setIsSigning(false);
    }
  }, [isReadyToQuest]);

  useEffect(() => {
    if (!isOnWrongNetwork) {
      setIsProcessing(connectIsLoading || signIsLoading || isSigning);
    }
  }, [connectIsLoading, signIsLoading, isSigning]);

  useEffect(() => {
    if (isOnWrongNetwork) {
      signOut();
      setIsProcessing(false);
    }
  }, [isOnWrongNetwork]);

  const getIsSignedIn = async () => {
    if (compLoading) return;
    compLoading = true;
    try {
      const serverUrl = formatServerUrl("/api/me");
      const res = await fetch(serverUrl);
      const json = await res.json();
      if (json.address !== undefined) {
        setSignedAddress(json.address);
        setIsSignedIn(true);
        setIsConnectError(null);
      } else {
        setIsSignedIn(false);
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      compLoading = false;
    }
  };

  const checkIfIsWrongNetwork = () => {
    if (chain !== undefined && chain.id !== envChainId) {
      setIsOnWrongNetwork(true);
    } else {
      setIsOnWrongNetwork(false);
    }
  };

  useEffect(() => {
    checkIfIsWrongNetwork();
  }, [chain]);

  useEffect(() => {
    const load = async () => {
      await getIsSignedIn();
    };

    const loadWithStatus = async () => {
      setIsGetUserLoading(true);
      await getIsSignedIn();
      setIsGetUserLoading(false);
    };

    const mobileProvider = (router.query.mobileProvider as string) || "";
    if (mobileProvider !== "") {
      logoutOnRefresh = false;
      login(mobileProvider, redirectToProfile);
    } else {
      loadWithStatus();
    }

    window.addEventListener("focus", load);
    return () => window.removeEventListener("focus", load);
  }, []);

  const addCompletedQuest = (questCollectionId: number) => {
    setUserCompletedQuestsAsynchronous([
      ...userCompletedQuestsAsynchronous,
      questCollectionId,
    ]);
  };

  const updateCompletedQuestsAsynchronous = async () => {
    try {
      const ledgerNFTContract = new ethers.Contract(
        process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_QUEST_CONTRACT,
        LedgerQuestContract,
        provider
      );

      const userCompletedQuestIds = [];
      ledgerNFTContract.walletOfOwner(address).then((tokenIdsOfOwner) => {
        tokenIdsOfOwner?.forEach((id) => {
          const categoryId = Math.floor(id / 10 ** 10);
          const collectionId = Math.floor(id / 10 ** 6) - categoryId * 10000;
          userCompletedQuestIds.push(Number(collectionId));
        });
        setUserCompletedQuestsAsynchronous(userCompletedQuestIds);
      });
    } catch (e) {
      logger.log("error", "[loginContextWalletOfOwner]", {
        bcCallMsg: e.message,
      });
    }
  };

  const setIsReady = async () => {
    await updateCompletedQuestsAsynchronous();
    setIsReadyToQuest(true);
    if (redirectToProfile) {
      router.push("/profile/hub");
    }
  };

  useEffect(() => {
    setIsReadyLoading(true);
    if (
      signedAddress &&
      isSignedIn &&
      !isOnWrongNetwork &&
      signer !== undefined
    ) {
      setIsReady();
    } else {
      setUserCompletedQuestsAsynchronous([]);
      setIsReadyToQuest(false);
    }

    // When we refresh the page, we have a weird display during the value is sent to the front
    // So we add a timeout to switch from not connected to connected
    const timeOut = setTimeout(() => {
      setIsReadyLoading(false);
    }, 200);

    return () => {
      clearTimeout(timeOut);
    };
  }, [isSignedIn, signer, isOnWrongNetwork, signedAddress]);

  useEffect(() => {
    if (!compLoading && isConnected && !isSignedIn) {
      tryToSignIn();
    } else if (!isConnected) {
      if (logoutOnRefresh) {
        logout();
      } else {
        logoutOnRefresh = true;
      }
    }
  }, [isConnected]);

  const addPolygonNetwork = async () => {
    const selectedConnector = connectors.filter(
      (c) => c.id === availableProviders.MetaMask
    );
    const provider = await selectedConnector[0].getProvider();
    try {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: utils.hexValue(137),
            blockExplorerUrls: ["https://polygonscan.com/"],
            chainName: "Polygon Mainnet",
            nativeCurrency: {
              decimals: 18,
              name: "MATIC",
              symbol: "MATIC",
            },
            rpcUrls: ["https://polygon-rpc.com"],
          },
        ],
      });
    } catch (err) {
      if (err.message.includes("already pending"))
        setIsConnectError(t("error.chainSwitchError"));
      else {
        setIsConnectError(t("error.globalErrorMessage"));
      }
    }
  };

  const tryToSignIn = useCallback(async () => {
    if (chain !== undefined && chain.id !== envChainId) {
      if (switchNetwork) {
        if (envChainId === 137) {
          await addPolygonNetwork();
        }
        switchNetwork(envChainId);
      }
    } else if (!isSignedIn) {
      await signIn();
    }
  }, [isSignedIn, chain]);

  const signIn = async () => {
    try {
      setIsConnectError(null);
      const chainId = chain?.id;
      if (!address || !chainId) return;
      const serverUrl = formatServerUrl("/api/nonceWagmi");
      const nonceRes = await fetch(serverUrl);
      const nonce = await nonceRes.text();

      const message = new SiweMessage({
        domain: window.location.host,
        address,
        statement: "Sign in with Ethereum to the app.",
        uri: window.location.origin,
        version: "1",
        chainId,
        nonce: nonce,
      });
      const signature = await signMessageAsync({
        message: message.prepareMessage(),
      });

      // Verify signature
      const verifyServerUrl = formatServerUrl("/api/verify");
      const verifyRes = await fetch(verifyServerUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message, signature }),
      });
      if (!verifyRes.ok) {
        logout();
        throw new Error("Error verifying message");
      }
    } catch (err) {
      if (err.message.includes("User denied message signature"))
        setIsConnectError(t("error.signatureRejected"));
      else if (err.message.includes("Error verifying message"))
        setIsConnectError(t("error.messageNotVerified"));
      else {
        setIsConnectError(t("error.signatureError"));
      }
    } finally {
      await getIsSignedIn();
    }
  };

  const hasExtensionInstalled = (selectedProvider: string) => {
    switch (selectedProvider) {
      case availableProviders.MetaMask:
        if (!window.ethereum) {
          return false;
        }
        if (window.ethereum.providers) {
          let mmInstalled = false;
          window.ethereum.providers.forEach((p) => {
            if (p.isMetaMask) mmInstalled = true;
          });
          return mmInstalled;
        } else if (!window.ethereum.isMetaMask) {
          return false;
        }
        return true;
      case availableProviders.Coinbase:
        if (!window.ethereum) {
          return false;
        }
        if (window.ethereum.providers) {
          let cbInstalled = false;
          window.ethereum.providers.forEach((p) => {
            if (p.isCoinbaseWallet) cbInstalled = true;
          });
          return cbInstalled;
        } else if (!window.ethereum.isCoinbaseWallet) {
          return false;
        }
        return true;
      default:
        return true;
    }
  };

  const canLoginOnDesktop = (selectedProvider: string) => {
    switch (selectedProvider) {
      case availableProviders.MetaMask:
        if (!hasExtensionInstalled(selectedProvider)) {
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            const hrefPage = window ? window.location.href : "";
            window.open(
              `https://metamask.app.link/dapp/${hrefPage}?mobileProvider=metaMask`
            );
          } else {
            window.open("https://metamask.io/download/", "_blank");
          }
          return false;
        }
        return true;

      case availableProviders.Coinbase:
        if (!hasExtensionInstalled(selectedProvider)) {
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            const hrefPage = window
              ? `${window.location.href}?mobileProvider=coinbaseWallet`
              : "";
            const encodedHREF = encodeURIComponent(hrefPage);
            window.open(`https://go.cb-w.com/dapp?cb_url=${encodedHREF}`);
          } else {
            window.open("https://www.coinbase.com/wallet", "_blank");
          }
          return false;
        }
        return true;

      default:
        return true;
    }
  };

  const isBrave = async () => {
    if (window.navigator.brave) {
      return await navigator.brave.isBrave();
    }
    return false;
  };

  const resetLedgerConnector = useCallback(() => {
    connectors[1] = new LedgerConnector({
      chains: defaultChains,
      options: {
        projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
        requiredChains: [
          Number(process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_CHAINID),
        ],
        enableDebugLogs: true,
      },
    });
  }, []);

  const login = useCallback(
    async (selectedProvider: string, shouldRedirectToProfile: boolean) => {
      setRedirectToProfile(shouldRedirectToProfile);
      if (selectedProvider === "ledger") {
        resetLedgerConnector();
      }

      setLastSelectedProvider(selectedProvider);
      let selectedConnector = null;
      if (
        selectedProvider === availableProviders.MetaMask &&
        (await isBrave())
      ) {
        const [firstConnector] = connectors.filter(
          (c) => c.id === availableProviders.Injected
        );
        selectedConnector = firstConnector;
      } else {
        if (!canLoginOnDesktop(selectedProvider)) {
          return;
        }

        const [firstConnector] = connectors.filter(
          (c) => c.id === selectedProvider
        );
        selectedConnector = firstConnector;
      }

      await connect({ connector: selectedConnector } as Partial<ConnectArgs>);
    },
    [isSignedIn]
  );

  const retry = useCallback(async () => {
    setIsConnectError(null);
    setIsSigning(false);
    await login(lastSelectedProvider, redirectToProfile);
  }, [lastSelectedProvider]);

  const reset = useCallback(async () => {
    if (connectIsLoading) {
      connectReset();
    }
    if (signIsLoading) {
      signReset();
    }
    setIsSigning(false);
    setIsLoading(false);
  }, [connectIsLoading, signIsLoading]);

  const logout = useCallback(async () => {
    await signOut();
    setIsSignedIn(false);
    await disconnectAsync();

    if (window?.location.pathname === "/result") {
      window.history.back();
    }
  }, []);

  const onChainProvider = useMemo(
    () => ({
      login,
      retry,
      reset,
      logout,
      isReadyToQuest,
      isOnWrongNetwork,
      isProcessing,
      isSignedIn,
      isConnectError,
      isSigning,
      isLoading,
      userCompletedQuestsAsynchronous,
      addCompletedQuest,
    }),
    [
      login,
      retry,
      reset,
      logout,
      isReadyToQuest,
      isOnWrongNetwork,
      isProcessing,
      isConnectError,
      isSigning,
      isLoading,
      userCompletedQuestsAsynchronous,
      addCompletedQuest,
    ]
  );
  // @ts-ignore
  return (
    // @ts-ignore
    <LoginContext.Provider value={{ onChainProvider }}>
      {children}
    </LoginContext.Provider>
  );
};
