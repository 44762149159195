const cache: { [key: string]: number } = {};

export const loadTokenPrices = async () => {
  const url =
    "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd";
  const response = await fetch(url);
  const data = await response.json();

  cache.ETH = data.ethereum.usd;
};

export const getTokenPrice = (symbol: string): number => Number(cache[symbol]);
