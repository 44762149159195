import React from "react";
import { useTranslation } from "next-i18next";
import WrongNetworkMessage from "./WrongNetworkMessage";
import { useLoginContext } from "../../../hooks";

const WrongNetwork = () => {
  const { t } = useTranslation("common");
  const { logout } = useLoginContext();

  return (
    <div className="flex gap-4">
      <button
        type="button"
        className="group captionText relative flex gap-2 items-center py-1 px-3 text-red bg-red-dark rounded"
      >
        <img
          src="/icons/warning-red.svg"
          alt="connected icon"
          className="w-4 h-4"
        />
        <div>{t("connectButton.wrongNetwork")}</div>
        <WrongNetworkMessage className="absolute right-1/2 translate-x-1/2 top-14 hidden group-hover:block" />
      </button>
      <button
        type="button"
        onClick={logout}
        className="flex gap-2 items-center self-end m-3"
      >
        <img src="/icons/logout.svg" alt="logout" className="w-4 h-4" />
      </button>
    </div>
  );
};

export default WrongNetwork;
