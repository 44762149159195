import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import ProfileButton from "./ProfileButton";

interface ConnectedPOKProps {
  poksEarned: number[];
  poksTotal: number[];
  closeMobileMenu: () => void;
}

const ConnectedPOK = ({
  poksEarned,
  poksTotal,
  closeMobileMenu,
}: ConnectedPOKProps) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    setCompleted(
      poksEarned.length !== 0 && poksEarned.length === poksTotal.length
    );
  }, [poksEarned, poksTotal]);

  const handleClick = () => {
    closeMobileMenu();
    router.push("/profile/hub");
  };

  return (
    <div className="flex gap-4">
      <button
        type="button"
        className={`flex gap-2 items-center py-1 px-3 captionText ${
          completed
            ? "text-green bg-green-dark hover:bg-green-hover"
            : "text-primary bg-primary-darker hover:bg-primary-dark"
        } rounded`}
        onClick={handleClick}
      >
        <img
          src={`${
            completed
              ? "/icons/achievment-green.svg"
              : "/icons/achievment-primary.svg"
          }`}
          alt="connected icon"
          className="w-4 h-4"
        />
        <div>
          {poksEarned.length}/{poksTotal.length}{" "}
          {t("connectButton.pokEarned", { count: poksEarned.length })}
        </div>
      </button>
      <ProfileButton closeMobileMenu={closeMobileMenu} />
    </div>
  );
};

export default ConnectedPOK;
