import React, { useState } from "react";
import Modal from "react-modal";
import router from "next/router";
import { useTranslation, Trans } from "next-i18next";
import CloseIcon from "@material-ui/icons/Close";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import {
  availableProviders,
  useLoginContext,
} from "../../hooks/web3login/login-context";
import styles from "./Login.module.css";
import ButtonLogin from "./ButtonLogin";
import LoginTrackingModal from "./LoginTrackingModal";
import { EnvType } from "../../src/types";

interface LoginProps {
  isOpen: boolean;
  redirectToProfile?: boolean;
  close: () => void;
}

function Login(props: LoginProps) {
  const { isOpen, close, redirectToProfile = false } = props;
  const title = "Connect Your Wallet";

  const {
    login,
    isProcessing,
    isOnWrongNetwork,
    isSignedIn,
    isConnectError,
    isSigning,
    reset,
  } = useLoginContext();
  const { t } = useTranslation("common");
  const [providerSelected, setProviderSelected] = useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const redirectToFaq = () => {
    router.push(`/faq#content5`);
    close();
  };

  const openModal = () => setIsOpen(true);

  const closeModal = () => {
    setIsOpen(false);
    reset();
  };

  const handleLogin = (selectedProvider) => {
    window.dataLayer.push({
      event: "login",
      method: selectedProvider,
    });

    setProviderSelected(true);
    login(selectedProvider, redirectToProfile);
    openModal();
    close();
  };

  const isNotProduction =
    (process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT as EnvType)?.trim() !== "prd";

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={close}
        className="flex flex-col sm:w-[450px] w-[90%] bg-black rounded-md absolute max-h-[80vh] text-center text-white pb-[32px] sm:px-[32px] px-[16px]"
        style={{
          overlay: {
            zIndex: 9999,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <button
          type="button"
          className="absolute right-[16px] top-[16px]"
          onClick={close}
        >
          <CloseIcon />
        </button>
        <div className="py-8 heading-3">{title}</div>

        <div
          className={`flex flex-col gap-2 justify-start items-center overflow-y-auto ${styles.scrollbar}`}
        >
          {isNotProduction ? (
            <ButtonLogin
              imgUrl="/icons/ledger_logo_mobile.svg"
              text="Ledger"
              action={() => handleLogin(availableProviders.LedgerConnect)}
            />
          ) : null}
          <ButtonLogin
            imgUrl="/icons/MetaMask-Logo.svg"
            text="MetaMask"
            action={() => handleLogin(availableProviders.MetaMask)}
          />
        </div>
        <div className="flex items-center mt-4 mb-2 ">
          <InfoOutlined fontSize="small" />
          <p className="ml-2 captionText text-left">{t("disclaimer.title")}</p>
        </div>
        <div className="captionText text-left">
          <Trans
            components={{
              linked: (
                <button
                  type="button"
                  onClick={() => redirectToFaq()}
                  className="underline cursor-pointer"
                />
              ),
            }}
            i18nKey="common:disclaimer.description"
          />
        </div>
      </Modal>
      {(isProcessing || providerSelected || isSignedIn) && (
        <LoginTrackingModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          isConnectError={isConnectError}
          isSignedIn={isSignedIn}
          isOnWrongNetwork={isOnWrongNetwork}
          isSigning={isSigning}
        />
      )}
    </>
  );
}

export default Login;
