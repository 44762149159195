import { v4 as uuidv4 } from "uuid";
import { IQuestion } from "../interfaces";
import { IAnswerIds, IQuestionIds, IQuizzIds } from "../src/interfaces";
import { apiRequests, APIRequestType } from "../src/types";
import logger from "./logger";
import formatServerUrl from "./formatServerUrl";

export const quizzRequest = async ({
  request,
  url,
  address,
  quizzId,
  quizz,
}) => {
  try {
    const response = await fetch(formatServerUrl(`/api/quizz`), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        request: request,
        address: address,
        quizzId: quizzId,
        quizz: quizz,
      }),
    });
    return response.json();
  } catch (e) {
    logger.log("info", "[quizz-api-28]", {
      error: e.message,
    });
    throw e;
  }
};

interface QuizzRandomizationData {
  url: string;
  address: string;
  quizzId: string;
  questions: IQuestion[];
}

const insertOrUpdateQuizzIds = async (
  url,
  address,
  quizzId,
  quizz
): Promise<boolean> => {
  const apiCallOptions = {
    url,
    address,
    quizzId,
    quizz,
  };

  const existsQuizzReq = await quizzRequest({
    ...apiCallOptions,
    request: apiRequests.EXISTS as APIRequestType,
  });
  const quizzExists = existsQuizzReq.result;

  let canInsert = true;
  if (quizzExists) {
    canInsert = false;
    const deleteQuizzReq = await quizzRequest({
      ...apiCallOptions,
      request: apiRequests.DELETE as APIRequestType,
    });

    if (deleteQuizzReq.result) {
      canInsert = true;
    } else {
      return false;
    }
  }

  if (canInsert) {
    const insertQuizzReq = await quizzRequest({
      ...apiCallOptions,
      request: apiRequests.INSERT as APIRequestType,
    });

    return insertQuizzReq.result;
  }
  return false;
};

export const randomizeAndStoreQuizzIds = async ({
  url,
  address,
  quizzId,
  questions,
}: QuizzRandomizationData): Promise<IQuestion[]> => {
  const quizzIds: IQuizzIds = {
    questions: [],
  };

  questions.map((question) => {
    const questionToUpdate = { ...question };
    const questionContentfulId = question.sys.id;
    const questionFakeId = uuidv4();
    questionToUpdate.sys.id = questionFakeId;

    const newQuestionIds: IQuestionIds = {
      contentfulId: questionContentfulId,
      fakeId: questionFakeId,
      answers: [],
    };

    const newAnswersIds: IAnswerIds[] = [];
    question.answersCollection.items.map((answer) => {
      const answerToUpdate = { ...answer };
      const answerContentfulId = answer.sys.id;
      const answerFakeId = uuidv4();
      answerToUpdate.sys.id = answerFakeId;
      newAnswersIds.push({
        contentfulId: answerContentfulId,
        fakeId: answerFakeId,
      });

      return answerToUpdate;
    });

    newQuestionIds.answers = newAnswersIds;

    quizzIds.questions.push(newQuestionIds);

    return questionToUpdate;
  });

  const inserted = await insertOrUpdateQuizzIds(
    url,
    address,
    quizzId,
    quizzIds
  );

  if (!inserted) {
    console.error("Error in ids generation");
    return [];
  }

  return questions;
};
