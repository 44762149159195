import { useRouter } from "next/router";
import React, { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { useAccount } from "wagmi";
import { useTranslation } from "next-i18next";
import { useLoginContext } from "../../../hooks";
import { getShortenWalletAddress } from "../../../helpers";
import WalletIcon from "../../WalletIcon";

interface ProfileMenuProps {
  closeMenu: () => void;
  closeMobileMainMenu: () => void;
  buttonRef: React.MutableRefObject<HTMLButtonElement>;
  className?: string;
}

const ProfileMenu = ({
  closeMenu,
  closeMobileMainMenu,
  buttonRef,
  className,
}: ProfileMenuProps) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { address, connector } = useAccount();
  const { logout } = useLoginContext();
  const compRef = useRef<HTMLDivElement>(null);

  const handledClickHub = () => {
    router.push("/profile/hub");
    closeMenu();
    closeMobileMainMenu();
  };

  const handledClickCollection = () => {
    router.push("/profile/collection");
    closeMenu();
    closeMobileMainMenu();
  };

  const handleClickOutside = (event) => {
    if (
      compRef.current &&
      !compRef.current.contains(event.target) &&
      buttonRef.current &&
      buttonRef.current !== event.target &&
      buttonRef.current !== event.target.offsetParent
    ) {
      closeMenu();
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside, true);
    return () => {
      window.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div
      onBlur={closeMenu}
      className={twMerge(
        "flex flex-col captionText bg-gray-800 rounded",
        className
      )}
      ref={compRef}
    >
      <div className="w-60 flex flex-col bg-gray-700 rounded m-4 px-2 py-3 gap-2">
        <div className="flex place-content-between">
          <div className="text-gray-500">
            {t("connectButton.popup.account")}
          </div>
          <div className="group relative">
            <div>{getShortenWalletAddress(address)}</div>
            <div className="absolute px-2 py-1 bg-gray-800 -right-8 phone:right-0 xl:left-1/2 xl:right-auto xl:-translate-x-1/2 -top-6 rounded hidden group-hover:block">
              {address}
            </div>
          </div>
        </div>
        <div className="flex place-content-between items-end">
          <div className="text-gray-500">{t("connectButton.popup.wallet")}</div>
          <div className="group relative">
            <WalletIcon connector={connector} className="w-4 h-4" />
            <div className="absolute px-2 py-1 bg-gray-800 left-1/2 -translate-x-1/2 -top-6 rounded hidden group-hover:block">
              {connector?.name}
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="px-4 py-2 text-left hover:bg-gray-700 capitalize"
        onClick={handledClickHub}
      >
        {t("connectButton.popup.hub")}
      </button>
      <button
        type="button"
        className="px-4 py-2 text-left hover:bg-gray-700 capitalize"
        onClick={handledClickCollection}
      >
        {t("connectButton.popup.collection")}
      </button>
      <button
        type="button"
        onClick={logout}
        className="flex gap-2 items-center self-end mx-4 my-2"
      >
        <img src="/icons/logout.svg" alt="logout" className="w-4 h-4" />
        <div>{t("connectButton.popup.logout")}</div>
      </button>
    </div>
  );
};

export default ProfileMenu;
