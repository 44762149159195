export const gRecaptchaResponseTypes = {
  SUCCESS: "SUCCESS",
  FAILURE_NOT_HUMAN: "FAILURE_NOT_HUMAN",
  FAILURE_METHOD_NOT_ALLOWED: "FAILURE_METHOD_NOT_ALLOWED",
};

export const isHuman = async (gRecaptchaToken: string) => {
  try {
    const res = await fetch("https://www.google.com/recaptcha/api/siteverify", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `secret=${process.env.GOOGLE_RECAPTCHA_SECRET_KEY}&response=${gRecaptchaToken}`,
    });

    const response = await res.json();
    if (response?.success) {
      return gRecaptchaResponseTypes.SUCCESS;
    }
    return gRecaptchaResponseTypes.FAILURE_NOT_HUMAN;
  } catch (err) {
    return gRecaptchaResponseTypes.FAILURE_METHOD_NOT_ALLOWED;
  }
};
