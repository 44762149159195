import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { useLoginContext } from "../../../hooks";
import NotConnected from "./NotConnected";
import { ConnectButtonStatusType } from "../../../src/types";
import Connected from "./Connected";
import ConnectedPOK from "./ConnectedPOK";
import WrongNetwork from "./WrongNetwork";
import { getAvailableQuestsCollection } from "../../../helpers";

interface IConnectButton {
  handleLogin: () => void;
  closeMobileMenu: () => void;
  stretch?: boolean;
}

function ConnectMenu({
  handleLogin,
  closeMobileMenu,
  stretch = false,
}: IConnectButton) {
  const { isReadyToQuest, isOnWrongNetwork, userCompletedQuestsAsynchronous } =
    useLoginContext();
  const { address } = useAccount();
  const [status, setStatus] =
    useState<ConnectButtonStatusType>("NOT_CONNECTED");
  const [poksEarned, setPoksEarned] = useState<number[]>([]);
  const [poksTotal, setPoksTotal] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isTimerOver, setIsTimerOver] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTimerOver(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const getPoksTotal = async () => {
    setLoading(true);
    const fetchedQuests = await getAvailableQuestsCollection(address);
    if (fetchedQuests?.ok) {
      const todoQuests = fetchedQuests.data;
      setPoksTotal(
        Array.from(new Set([...todoQuests, ...userCompletedQuestsAsynchronous]))
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isReadyToQuest) {
      setPoksEarned(userCompletedQuestsAsynchronous);
      getPoksTotal();
    } else {
      setPoksEarned([]);
      setPoksTotal([]);
    }
  }, [isReadyToQuest, userCompletedQuestsAsynchronous]);

  useEffect(() => {
    if (isOnWrongNetwork) {
      setStatus("WRONG_NETWORK");
    } else if (!isReadyToQuest) {
      setStatus("NOT_CONNECTED");
    } else if (loading || !isTimerOver) {
      setStatus("CONNECTED");
    } else {
      setStatus("CONNECTED_POK");
    }
  }, [isReadyToQuest, isOnWrongNetwork, loading, isTimerOver]);

  return {
    NOT_CONNECTED: <NotConnected handleLogin={handleLogin} stretch={stretch} />,
    CONNECTED: <Connected closeMobileMenu={closeMobileMenu} />,
    CONNECTED_POK: (
      <ConnectedPOK
        poksEarned={poksEarned}
        poksTotal={poksTotal}
        closeMobileMenu={closeMobileMenu}
      />
    ),
    WRONG_NETWORK: <WrongNetwork />,
  }[status];
}

export default ConnectMenu;
