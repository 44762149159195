import { IMoreQuest, IPresentationQuest } from "../src/interfaces";
import { IAntiCheat } from "../interfaces";
import {
  HomeQuestsWithoutConnexionStatus,
  HomeQuestsWithoutConnexionStatusType,
  MoreQuestsStatus,
  MoreQuestsStatusType,
} from "../src/types";
import { getIsOnMaintenance } from "./api-endpoints-wrapper";

export const prettifySeconds = (seconds?: number, resolution?: string) => {
  if (seconds !== 0 && !seconds) {
    return "";
  }

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  if (resolution === "day") {
    return d + (d === 1 ? " day" : " days");
  }

  const dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
  const hDisplay = h > 0 ? h + (h === 1 ? " Hour, " : " Hours, ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " Min" : " Mins") : "";

  return dDisplay + hDisplay + mDisplay;
};

export const getDifferenceInDays = (date1: Date, date2: Date): number => {
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = diffInTime / (1000 * 3600 * 24);

  return diffInDays > 0 ? Number(Number(diffInDays).toFixed(0)) : 0;
};

export const setAll = (state: any, properties: any) => {
  const props = Object.keys(properties);
  props.forEach((key) => {
    state[key] = properties[key];
  });
};

export const shorten = (str: string): string => {
  if (str.length < 10) return str;
  return `${str.slice(0, 6)}...${str.slice(str.length - 4)}`;
};

export const sleep = (seconds: number) =>
  new Promise((resolve) => setTimeout(() => resolve(null), seconds * 1000));

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
};

const Status = {
  DaysLeft: 0,
  Available: 1,
  ComingSoon: 2,
  Ended: 3,
};

const getStatus = (quest, now) => {
  const startDate = new Date(quest.startDate);
  const endDate = new Date(quest.endDate);

  if (now > startDate) {
    if (!quest.endDate) return Status.Available;
    if (now < endDate) return Status.DaysLeft;
    return Status.Ended;
  }
  return Status.ComingSoon;
};

export const sortQuests = (a, b, serverNow) => {
  const statusA = getStatus(a, serverNow);
  const statusB = getStatus(b, serverNow);

  if (statusA < statusB) return -1;
  if (statusA > statusB) return 1;

  switch (statusA) {
    case Status.DaysLeft: {
      const aHasEndDate = !!a.endDate;
      const bHasEndDate = !!b.endDate;

      if (aHasEndDate && !bHasEndDate) {
        return -1; // Move a with endDate before b without endDate
      } else if (!aHasEndDate && bHasEndDate) {
        return 1; // Move b with endDate before a without endDate
      } else if (aHasEndDate && bHasEndDate) {
        // Sort by endDate if both items have endDate
        return new Date(a.endDate).getTime() - new Date(b.endDate).getTime();
      }
      return 0;
    }
    case Status.Available:
      return a.startDate > b.startDate ? -1 : 1;
    case Status.ComingSoon:
      return a.startDate < b.startDate ? -1 : 1;
    case Status.Ended:
      return a.endDate > b.endDate ? -1 : 1;
    default:
      return 0;
  }
};

export const getMoreQuestsStatus = (quest, now): MoreQuestsStatusType => {
  const hasEndDate = !!quest.endDate;
  const startDate = new Date(quest.startDate);
  const endDate = new Date(quest.endDate);

  if (quest.claimStatus) {
    if (quest.completed) return MoreQuestsStatus.COMPLETED_CLAIM;
    return MoreQuestsStatus.NOT_COMPLETED_CLAIM;
  }

  if (hasEndDate && endDate < now) {
    return MoreQuestsStatus.ENDED;
  }

  if (quest.completed) {
    if (quest.tokenGated) {
      if (hasEndDate) return MoreQuestsStatus.COMPLETED_TG_END_DATE;
      return MoreQuestsStatus.COMPLETED_TG_NO_END_DATE;
    }
    if (hasEndDate) return MoreQuestsStatus.COMPLETED_OPENED_END_DATE;
    return MoreQuestsStatus.COMPLETED_OPENED_NO_END_DATE;
  }

  if (startDate > now) return MoreQuestsStatus.COMING_SOON;

  if (quest.hasRequirements) {
    if (quest.tokenGated) {
      if (hasEndDate) return MoreQuestsStatus.NOT_COMPLETED_TG_END_DATE;
      return MoreQuestsStatus.NOT_COMPLETED_TG_NO_END_DATE;
    }
    if (hasEndDate) return MoreQuestsStatus.NOT_COMPLETED_OPENED_END_DATE;
    return MoreQuestsStatus.NOT_COMPLETED_OPENED_NO_END_DATE;
  }

  return MoreQuestsStatus.NOT_REQUIRED_TOKENS;
};

const getEasiestQuestOrder = (a, b) => {
  if (a.difficultyLevel < b.difficultyLevel) return -1;
  if (a.difficultyLevel > b.difficultyLevel) return 1;
  return 0;
};

export const sortMoreQuests = (a, b) => {
  if (a.status < b.status) return -1;
  if (a.status > b.status) return 1;

  const startDateA = new Date(a.startDate);
  const startDateB = new Date(b.startDate);
  const endDateA = new Date(a.endDate);
  const endDateB = new Date(b.endDate);

  switch (a.status) {
    case MoreQuestsStatus.NOT_COMPLETED_OPENED_END_DATE:
    case MoreQuestsStatus.NOT_COMPLETED_TG_END_DATE:
    case MoreQuestsStatus.COMPLETED_OPENED_END_DATE:
    case MoreQuestsStatus.COMPLETED_TG_END_DATE:
      if (endDateA < endDateB) return -1;
      if (endDateA > endDateB) return 1;
      return getEasiestQuestOrder(a, b);
    case MoreQuestsStatus.NOT_COMPLETED_OPENED_NO_END_DATE:
    case MoreQuestsStatus.NOT_COMPLETED_TG_NO_END_DATE:
    case MoreQuestsStatus.COMPLETED_OPENED_NO_END_DATE:
    case MoreQuestsStatus.COMPLETED_TG_NO_END_DATE:
      if (startDateA > startDateB) return -1;
      if (startDateA < startDateB) return 1;
      return getEasiestQuestOrder(a, b);
    case MoreQuestsStatus.COMING_SOON:
      if (startDateA < startDateB) return -1;
      if (startDateA > startDateB) return 1;
      return getEasiestQuestOrder(a, b);
    case MoreQuestsStatus.COMPLETED_CLAIM:
    case MoreQuestsStatus.NOT_COMPLETED_CLAIM:
      if (startDateA < startDateB) return -1;
      if (startDateA > startDateB) return 1;
      return 0;
    case MoreQuestsStatus.ENDED:
      if (endDateA > endDateB) return -1;
      if (endDateA < endDateB) return 1;
      return 0;
    case MoreQuestsStatus.NOT_REQUIRED_TOKENS:
    default:
      return 0;
  }
};

export const getHomeQuestsStatusWithoutConnexion = (
  quest,
  now
): HomeQuestsWithoutConnexionStatusType => {
  const hasEndDate = !!quest.endDate;
  const startDate = new Date(quest.startDate);
  const endDate = new Date(quest.endDate);

  if (quest.claimStatus) {
    return HomeQuestsWithoutConnexionStatus.CLAIM;
  }

  if (hasEndDate && endDate < now) {
    return HomeQuestsWithoutConnexionStatus.ENDED;
  }

  if (startDate > now) return HomeQuestsWithoutConnexionStatus.COMING_SOON;

  if (quest.tokenGated) {
    if (hasEndDate) return HomeQuestsWithoutConnexionStatus.TG_END_DATE;
    return HomeQuestsWithoutConnexionStatus.TG_NO_END_DATE;
  }

  if (hasEndDate) return HomeQuestsWithoutConnexionStatus.OPENED_END_DATE;
  return HomeQuestsWithoutConnexionStatus.OPENED_NO_END_DATE;
};

export const sortHomeQuestsWithoutConnexion = (a, b) => {
  if (a.status < b.status) return -1;
  if (a.status > b.status) return 1;

  const startDateA = new Date(a.startDate);
  const startDateB = new Date(b.startDate);
  const endDateA = new Date(a.endDate);
  const endDateB = new Date(b.endDate);

  switch (a.status) {
    case HomeQuestsWithoutConnexionStatus.OPENED_END_DATE:
    case HomeQuestsWithoutConnexionStatus.TG_END_DATE:
      if (endDateA < endDateB) return -1;
      if (endDateA > endDateB) return 1;
      return getEasiestQuestOrder(a, b);
    case HomeQuestsWithoutConnexionStatus.OPENED_NO_END_DATE:
    case HomeQuestsWithoutConnexionStatus.TG_NO_END_DATE:
      if (startDateA > startDateB) return -1;
      if (startDateA < startDateB) return 1;
      return getEasiestQuestOrder(a, b);
    case HomeQuestsWithoutConnexionStatus.COMING_SOON:
      if (startDateA < startDateB) return -1;
      if (startDateA > startDateB) return 1;
      return getEasiestQuestOrder(a, b);
    case HomeQuestsWithoutConnexionStatus.CLAIM:
      if (startDateA < startDateB) return -1;
      if (startDateA > startDateB) return 1;
      return 0;
    case HomeQuestsWithoutConnexionStatus.ENDED:
      if (endDateA > endDateB) return -1;
      if (endDateA < endDateB) return 1;
      return 0;
    default:
      return 0;
  }
};

export const moreQuestToPresentationQuest = (
  quest: IMoreQuest
): IPresentationQuest => ({
  id: quest.id,
  questPicture: quest.questPicture,
  title: quest.title,
  slug: quest.slug,
  startDate: quest.startDate,
  endDate: quest.endDate,
  categoryId: quest.categoryId,
  collectionId: quest.collectionId,
  tokenGated: quest.tokenGated,
  partnerName: quest.partnerName,
  completed: quest.completed,
});

export const getSlugFromURL = (url: string) => {
  if (!url) return "";
  if (url.length === 0) return "";

  const urlWithoutMobileProvider = url.replace("?mobileProvider=metaMask", "");

  const lastSlashPos = urlWithoutMobileProvider.lastIndexOf("/");

  if (lastSlashPos === -1) return urlWithoutMobileProvider;

  return urlWithoutMobileProvider.substring(lastSlashPos + 1);
};

export const getCategoryId = (category) => {
  switch (category) {
    case "Academy":
      return 1;
    case "Community":
      return 2;
    case "Partner":
    default:
      return 3;
  }
};

export const secToMms = (seconds: number) => seconds * 1000;
export const minToMms = (seconds: number) => seconds * 60000;

export const getTotaltimeToAnswer = (seconds: number, minutes: number) =>
  secToMms(seconds) + minToMms(minutes);

export const getAllTimesGreaterThanMin = (
  minTimeToAnswerPerQuestion: number,
  elapsedTimes: number[]
) => elapsedTimes.every((time) => time > minTimeToAnswerPerQuestion);

export const getAllTimesAreEqual = (elapsedTimes: number[]) =>
  elapsedTimes.every((time, array) => time === array[0]);

export const getMinTimeToAnswerPerQuestion = (antiCheat: IAntiCheat) =>
  antiCheat?.minimumTimePerQuestion || 0;

export const getMinTimeToAnswerAllQuestions = (
  antiCheat: IAntiCheat,
  elapsedTimes: number[]
) =>
  elapsedTimes.length *
  (antiCheat?.valueToCalcMinTimeToAnswerAllQuestions || 0);

export const isUserABot = (
  antiCheat: IAntiCheat,
  elapsedTimes: number[],
  seconds: number,
  minutes: number
): boolean => {
  const minTimeToAnswerPerQuestion = getMinTimeToAnswerPerQuestion(antiCheat);
  const minTimeToAnswerAllQuestions = getMinTimeToAnswerAllQuestions(
    antiCheat,
    elapsedTimes
  );
  const allTimesGreaterThanMin = getAllTimesGreaterThanMin(
    minTimeToAnswerPerQuestion,
    elapsedTimes
  );
  const allTimesAreEqual = getAllTimesAreEqual(elapsedTimes);
  // get the total time (seconds + minutes)
  const totalTime = getTotaltimeToAnswer(seconds, minutes);
  const minTimeToAnswer = totalTime > minTimeToAnswerAllQuestions;
  const isBot = !allTimesGreaterThanMin || !minTimeToAnswer || allTimesAreEqual;
  return isBot;
};

export const dateToMonthYear = (date: string) => {
  const dateObj = new Date(date);
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  return `${month}/${year}`;
};

export const sharedServerSide = (getServerSidePropsFunc) => async (context) => {
  const isOnMaintenance = await getIsOnMaintenance();

  if (isOnMaintenance) {
    return {
      redirect: {
        permanent: false,
        destination: "/maintenance",
      },
    };
  }

  if (getServerSidePropsFunc) {
    return getServerSidePropsFunc(context);
  }

  return { props: {} };
};

export const getShortenWalletAddress = (walletAddress: string): string => {
  if (!walletAddress) return null;
  if (walletAddress.length !== 42) return walletAddress;
  return `${walletAddress.slice(0, 6)}...${walletAddress.slice(38, 42)}`;
};
