import { ethers } from "ethers";
import Moralis from "moralis";
import { formatEther } from "ethers/lib/utils.js";
import { ITokenGate } from "../interfaces";
import { GeneralTokenContract } from "../abi";
import { TokenRequirementsType } from "../src/types";
import logger from "./logger";
import formatServerUrl from "./formatServerUrl";

const getOwnedContractsByChain = async (
  walletAddress: string,
  chainId: number
): Promise<string[]> => {
  try {
    const list: string[] = [];

    if (!Moralis.Core.isStarted) {
      await Moralis.start({
        apiKey: process.env.MORALIS_API_SECRET_KEY,
      });
    }

    let cursor = null;

    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await Moralis.EvmApi.nft.getWalletNFTCollections({
        address: walletAddress,
        chain: chainId,
        cursor,
      });

      response.result.forEach((collection) => {
        list.push(collection.tokenAddress.lowercase);
      });
      cursor = response.pagination.cursor;
    } while (cursor !== "" && cursor !== null);

    const setUniqueCollections = new Set(list);
    const listUniqueCollections = Array.from(setUniqueCollections);
    return listUniqueCollections;
  } catch (e) {
    logger.log("error", "[NFTBalance]", { bcCallMsg: e.message });
    throw new Error("Error fetching the collections list.");
  }
};

const hasOneOfTokenGatesByChain = async (
  walletAddress: string,
  tokenGates: ITokenGate[]
): Promise<ITokenGate[]> => {
  try {
    if (tokenGates.length === 0) return [];

    const ownedContracts = await getOwnedContractsByChain(
      walletAddress,
      tokenGates[0].tokenGateChainId
    );

    return tokenGates.map((tokenGate) => ({
      ...tokenGate,
      hasRequirements: ownedContracts.includes(
        tokenGate.tokenGateAddress.toLowerCase()
      ),
    }));
  } catch (e) {
    throw new Error(e.message);
  }
};

const getNFTCollectionNumber = async (
  tokenGate: ITokenGate
): Promise<ITokenGate> => {
  if (!Moralis.Core.isStarted) {
    await Moralis.start({
      apiKey: process.env.MORALIS_API_SECRET_KEY,
    });
  }

  let count = 0;
  const response = await Moralis.EvmApi.nft.getNFTCollectionStats({
    address: tokenGate.tokenGateAddress,
    chain: tokenGate.tokenGateChainId.toString(),
  });
  const rawResponse = response.raw;
  count = Number(rawResponse.total_tokens);
  return { ...tokenGate, numberInCollection: count };
};

const hasOneOfTokenGates = async (
  walletAddress: string,
  tokenGates: ITokenGate[]
): Promise<ITokenGate[]> => {
  if (tokenGates.length === 0) return [];
  try {
    const tokenGatesByChain = tokenGates.reduce((acc, tg) => {
      acc[tg.tokenGateChainId] = tokenGates.filter(
        (tgc) => tgc.tokenGateChainId === tg.tokenGateChainId
      );
      return acc;
    }, {});

    const tokenGatesByChainArray = Object.values(tokenGatesByChain);

    const tokenGatesWithRequirements = await Promise.all(
      tokenGatesByChainArray.map(async (tgs) =>
        hasOneOfTokenGatesByChain(walletAddress, tgs as ITokenGate[])
      )
    );

    const NFTWithRequirements = tokenGatesWithRequirements.flat();
    const NFTWithCollectionNumber = await Promise.all(
      NFTWithRequirements.map(async (tg) => getNFTCollectionNumber(tg))
    );

    return NFTWithCollectionNumber;
  } catch (e) {
    throw new Error(e.message);
  }
};

const getContractBalance = async (
  walletAddress: string,
  tokenGate: ITokenGate
) => {
  const provider = new ethers.providers.InfuraProvider(
    tokenGate.tokenGateChainId,
    process.env.NEXT_PUBLIC_INFURA_API_KEY
  );
  const tokenContract = new ethers.Contract(
    tokenGate.tokenGateAddress,
    GeneralTokenContract,
    provider
  );

  try {
    const balance = await tokenContract.balanceOf(walletAddress);
    const balanceNumber = Number(formatEther(balance));
    if (tokenGate.tokenGateName === "PAYPAL USD") {
      return balanceNumber * 1000000000000;
    }
    return balanceNumber;
  } catch (e) {
    logger.log("error", "[erc20Balance]", { bcCallMsg: e.message });
    throw new Error(e.message);
  }
};

const getERC20Requirements = async (
  walletAddress: string,
  tokenGates: ITokenGate[]
): Promise<{ status: TokenRequirementsType; data: ITokenGate[] }> => {
  if (tokenGates.length === 0) {
    return {
      status: "PASSED",
      data: [],
    };
  }

  const tokenGatesCopy = [...tokenGates];

  const tokenGatesWithBalance = await Promise.all(
    tokenGatesCopy.map(async (tokenGate) => {
      const balance = await getContractBalance(walletAddress, tokenGate);
      return {
        ...tokenGate,
        balance,
      };
    })
  );

  let passed = false;
  let notEnough = false;

  const tokenGatesWithInfo = tokenGatesWithBalance.map((tg) => {
    let hasRequirements = false;
    if (tg.erc20MinimumNeeded > 0) {
      if (tg.balance < tg.erc20MinimumNeeded) {
        notEnough = true;
      } else {
        hasRequirements = true;
        passed = true;
      }
    } else if (tg.balance > 0) {
      hasRequirements = true;
      passed = true;
    }
    const { balance: _, ...tgWithoutBalance } = tg;
    return { ...tgWithoutBalance, hasRequirements: hasRequirements };
  });

  if (passed) {
    return {
      status: "PASSED",
      data: tokenGatesWithInfo,
    };
  }
  if (notEnough) {
    return {
      status: "NOT_ENOUGH_TOKENS",
      data: tokenGatesWithInfo,
    };
  }
  return {
    status: "NOT_REQUIRED_TOKENS",
    data: tokenGatesWithInfo,
  };
};

export const getUserTokenRequirementsFromList = async (
  walletAddress: string,
  tokenGates: ITokenGate[]
) => {
  try {
    if (tokenGates.length === 0) {
      return {
        status: "PASSED",
        data: [],
      };
    }

    // Separate tokenGates by type
    const NFTTokenGates: ITokenGate[] = [];
    const ERC20TokenGates: ITokenGate[] = [];

    await Promise.all(
      tokenGates.map(async (tokenGate) => {
        if (tokenGate.tokenType === "ERC20") {
          ERC20TokenGates.push(tokenGate);
        } else {
          NFTTokenGates.push(tokenGate);
        }
      })
    );

    const [hasNFTTokenGates, ERC20Requirements] = await Promise.all([
      hasOneOfTokenGates(walletAddress, NFTTokenGates),
      getERC20Requirements(walletAddress, ERC20TokenGates),
    ]);

    const data = [...hasNFTTokenGates, ...ERC20Requirements.data];

    let hasOneNFT = false;
    hasNFTTokenGates.forEach((token) => {
      if (token.hasRequirements) {
        hasOneNFT = true;
      }
    });

    if (NFTTokenGates.length > 0 && hasOneNFT)
      return {
        status: "PASSED",
        data: data,
      };

    if (ERC20TokenGates.length > 0) {
      return { status: ERC20Requirements.status, data: data };
    }

    return { status: "NOT_REQUIRED_TOKENS", data: data };
  } catch (e) {
    console.log(`Error fetching the balances. Message: ${e.message}`);
    return null;
  }
};

export const getUserTokenRequirements = async (
  walletAddress: string,
  tokenGates: ITokenGate[]
) => {
  if (typeof window !== "undefined") {
    // On Client : we use an API to not expose the infura key
    try {
      const serverUrl = formatServerUrl("/api/tokenGates");
      const response = await fetch(serverUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          address: walletAddress,
          tokenGates: tokenGates,
        }),
      });
      const result = await response.json();
      return result;
    } catch (e) {
      console.log(`Error fetching the balances. Message: ${e.message}`);
      return null;
    }
  } else {
    // On Server : we use the infura key directly to avoid a loop calling the API internally
    return getUserTokenRequirementsFromList(walletAddress, tokenGates);
  }
};

export const getTokenRequirementsWOUserFromList = async (
  tokenGates: ITokenGate[]
) => {
  try {
    if (tokenGates.length === 0) {
      return {
        status: "PASSED",
        data: [],
      };
    }

    // Separate tokenGates by type
    const NFTTokenGates: ITokenGate[] = [];
    const ERC20TokenGates: ITokenGate[] = [];

    await Promise.all(
      tokenGates.map(async (tokenGate) => {
        if (tokenGate.tokenType === "ERC20") {
          ERC20TokenGates.push(tokenGate);
        } else {
          NFTTokenGates.push(tokenGate);
        }
      })
    );

    const NFTsWithCollectionNumber = await Promise.all(
      NFTTokenGates.map(async (tg) => getNFTCollectionNumber(tg))
    );

    const allTokenGates = [...NFTsWithCollectionNumber, ...ERC20TokenGates];

    const allTokenGatesWithRequirements = allTokenGates.map((tg) => ({
      ...tg,
      hasRequirements: true,
    }));

    return { status: "PASSED", data: allTokenGatesWithRequirements };
  } catch (e) {
    console.log(
      `Error fetching the NFT collections counts. Message: ${e.message}`
    );
    return null;
  }
};

export const getTokenRequirementsWithoutUser = async (
  tokenGates: ITokenGate[]
) => {
  if (typeof window !== "undefined") {
    // On Client : we use an API to not expose the infura key
    try {
      const serverUrl = formatServerUrl("/api/tokenGatesWOUser");
      const response = await fetch(serverUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          tokenGates: tokenGates,
        }),
      });
      const result = await response.json();
      return result;
    } catch (e) {
      console.log(
        `Error fetching the NFT collections counts. Message: ${e.message}`
      );
      return null;
    }
  } else {
    // On Server : we use the infura key directly to avoid a loop calling the API internally
    return getTokenRequirementsWOUserFromList(tokenGates);
  }
};
