import { createConfig, configureChains } from "wagmi";
import { polygon, goerli } from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { LedgerConnector } from "wagmi/connectors/ledger";
import { InjectedConnector } from "wagmi/connectors/injected";
import type { IronSessionOptions } from "iron-session";
import { EnvType } from "../src/types";

const getInfuraAPIKey = () => {
  const infuraUrl = process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_NETWORK_URI;
  const goerliApiKey =
    infuraUrl && infuraUrl.includes("goerli")
      ? (infuraUrl.replace("https://goerli.infura.io/v3/", "") as string)
      : "";
  // Ropsten is depricated
  const ropStenApiKey =
    infuraUrl && infuraUrl.includes("ropsten")
      ? (infuraUrl.replace("https://ropsten.infura.io/v3/", "") as string)
      : "";
  const polygonApiKey =
    infuraUrl && infuraUrl.includes("polygon")
      ? (infuraUrl.replace(
          "https://polygon-mainnet.infura.io/v3/",
          ""
        ) as string)
      : "";
  const apiKey = goerliApiKey || polygonApiKey || ropStenApiKey;
  return apiKey;
};
const infuraApiKey: string = getInfuraAPIKey();

const isDev =
  (process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT as EnvType) === "dev";

export const ironOptions: IronSessionOptions = {
  cookieName: "siwe",
  password: process.env.IRON_SESSION_SECRET,
  cookieOptions: {
    secure: !isDev,
  },
};

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [polygon, goerli],
  [infuraProvider({ apiKey: infuraApiKey })]
);

export const defaultChains = chains;

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new LedgerConnector({
      chains,
      options: {
        projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
        requiredChains: [
          Number(process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_CHAINID),
        ],
        enableDebugLogs: true,
      },
    }),
    new InjectedConnector({
      chains,
    }),
  ],
  publicClient,
  webSocketPublicClient,
  logger: {
    warn: null,
  },
});

export default config;
