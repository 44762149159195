import React, { useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function Footer() {
  const { t } = useTranslation("common");
  const router = useRouter();
  const [showLearning, setShowLearning] = useState<boolean>(true);
  const [showExplore, setShowExplore] = useState<boolean>(true);
  const [showSecure, setShowSecure] = useState<boolean>(true);
  const [showLegal, setShowLegal] = useState<boolean>(true);
  return (
    <div className="w-full flex justify-center bg-black">
      <div className="md:w-[750px] lg:w-[1200px] w-full px-4 md:pt-20 pt-[60px] md:pb-[94px] pb-[60px] flex md:flex-row flex-col">
        <div className="md:w-[248px] w-full">
          <img src="/images/QuestLogo.svg" alt="" />
          <div className="mt-[38px] captionText text-gray-600">
            {t("footer.copyright")}
          </div>
        </div>
        <div className="md:ml-16 flex md:flex-row flex-col font-inter text-gray-500 md:mt-0 mt-10 gap-y-10 md:gap-x-20">
          <div className="flex flex-col gap-3">
            <div className="flex flex-row justify-between bodyText text-white">
              <div>{t("footer.title1")}</div>
              <button
                type="button"
                className="block md:hidden"
                onClick={() => setShowExplore(!showExplore)}
              >
                {showExplore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </button>
            </div>
            <div className={showExplore ? "flex flex-col gap-3" : "hidden"}>
              <button
                type="button"
                className="captionText text-left"
                onClick={() => router.push("/")}
              >
                {t("pages.home")}
              </button>
              <button
                type="button"
                className="captionText text-left"
                onClick={() => router.push("/quests")}
              >
                {t("pages.quests")}
              </button>
              <button
                type="button"
                className="captionText text-left"
                onClick={() => router.push("/faq")}
              >
                {t("pages.faq")}
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex flex-row justify-between bodyText text-white">
              <div>{t("footer.title2")}</div>
              <button
                type="button"
                className="block md:hidden"
                onClick={() => setShowLearning(!showLearning)}
              >
                {showLearning ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </button>
            </div>
            <div className={showLearning ? "flex flex-col gap-3" : "hidden"}>
              <button type="button" className="captionText text-left">
                <a
                  href="https://www.ledger.com/academy"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("footer.learning1")}
                </a>
              </button>
              <button type="button" className="captionText text-left">
                <a
                  href="https://www.ledger.com/academy/school-of-block"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("footer.learning2")}
                </a>
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex flex-row justify-between bodyText text-white">
              <div>{t("footer.title3")}</div>
              <button
                type="button"
                className="block md:hidden"
                onClick={() => setShowSecure(!showSecure)}
              >
                {showSecure ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </button>
            </div>
            <button
              type="button"
              className={showSecure ? "captionText text-left" : "hidden"}
            >
              <a
                href="https://shop.ledger.com/"
                target="_blank"
                rel="noreferrer"
              >
                {t("footer.secure")}
              </a>
            </button>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex flex-row justify-between bodyText text-white">
              <div>{t("footer.title4")}</div>
              <button
                type="button"
                className="block md:hidden"
                onClick={() => setShowLegal(!showLegal)}
              >
                {showLegal ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </button>
            </div>
            <div className={showLegal ? "captionText text-left" : "hidden"}>
              <div className={showLegal ? "flex flex-col gap-3" : "hidden"}>
                <button type="button" className="captionText text-left">
                  <a
                    href="https://quest.ledger.com/terms-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("footer.termsAndConditions")}
                  </a>
                </button>
                <button type="button" className="captionText text-left">
                  <a
                    href="https://www.ledger.com/pages/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("footer.policy")}
                  </a>
                </button>
                <button type="button" className="captionText text-left">
                  <a
                    href="https://shop.ledger.com/pages/cookie-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("footer.cookies")}
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
