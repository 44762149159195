import { Link } from "react-scroll";
import React from "react";
import { twMerge } from "tailwind-merge";

interface ButtonChildrenProps {
  label: string;
  svgIcon?: "Arrowright" | "ExternalLink" | "arrow-bottom-white";
  jsxIcon?: JSX.Element;
  disabled?: boolean;
  stretch?: boolean;
  nowrap?: boolean;
}

interface RealButtonChildrenProps extends ButtonChildrenProps {
  textColor?: "white" | "black";
}

function ButtonChildren(props: RealButtonChildrenProps) {
  const {
    label,
    svgIcon = null,
    jsxIcon = null,
    disabled = false,
    textColor = "black",
    stretch = false,
    nowrap = false,
  } = props;

  return (
    <>
      <span
        className={`normal-case bodyText ${
          disabled ? "text-gray-600" : `text-${textColor} transition-left`
        }
        ${nowrap ? "whitespace-nowrap" : ""}`}
      >
        {label}
      </span>
      {svgIcon && (
        <img
          src={`/icons/Homepage/${svgIcon}.svg`}
          alt=""
          className={`${disabled ? "brightness-50" : "transition-right"} ${
            stretch ? "ml-auto" : ""
          }`}
          data-testid="svg-element"
          data-cy="StartQuest"
        />
      )}
      {jsxIcon && (
        <div
          data-testid="jsx-element"
          className={`transition-right ${stretch ? "ml-auto" : ""}`}
        >
          {jsxIcon}
        </div>
      )}
    </>
  );
}
export interface Props extends ButtonChildrenProps {
  type?: "primary" | "secondary" | "withWhiteBorder" | "gray" | "white";
  href?: string;
  onClick?: () => void;
  link?: string;
  className?: string;
  stretch?: boolean;
  nowrap?: boolean;
}

const Button = ({
  label,
  svgIcon,
  jsxIcon,
  onClick,
  href,
  link,
  type = "primary",
  disabled,
  className = "",
  stretch = false,
  nowrap = false,
}: Props) => {
  const typeStyles = () => {
    if (!disabled) {
      if (type === "primary") return "bg-primary text-white";
      if (type === "secondary") return "bg-black text-white";
      if (type === "withWhiteBorder") return "border border-white";
      if (type === "gray") return "bg-gray-400";
      if (type === "white") return "bg-white";
    } else return "bg-gray-400";
    return "";
  };

  const buttonClasses = twMerge(
    `group px-6 py-4 xl:px-8 xl:py-5 rounded-full flex items-center gap-4 ${typeStyles()}`,
    stretch ? "w-full" : "w-max",
    className
  );

  const textColor = type === "gray" || type === "white" ? "black" : "white";

  if (href) {
    return (
      <a
        className={twMerge(`${buttonClasses} cursor-pointer`, className)}
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        <ButtonChildren
          label={label}
          svgIcon={svgIcon}
          jsxIcon={jsxIcon}
          textColor={textColor}
          stretch={stretch}
          nowrap={nowrap}
        />
      </a>
    );
  }

  if (link) {
    return (
      <Link
        to={link}
        smooth
        className={twMerge(`${buttonClasses} cursor-pointer`, className)}
        data-testid="react-scroll"
      >
        <ButtonChildren
          label={label}
          svgIcon={svgIcon}
          jsxIcon={jsxIcon}
          textColor={textColor}
          stretch={stretch}
          nowrap={nowrap}
        />
      </Link>
    );
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={twMerge(buttonClasses, className)}
      disabled={disabled}
      data-testid="button"
    >
      <ButtonChildren
        label={label}
        svgIcon={svgIcon}
        jsxIcon={jsxIcon}
        disabled={disabled}
        textColor={textColor}
        stretch={stretch}
        nowrap={nowrap}
      />
    </button>
  );
};

export default Button;
