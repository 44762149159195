import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useRouter } from "next/router";

import Header from "../Header";
import Footer from "../Footer";

interface IViewBaseProps {
  children: React.ReactNode;
  hideNavigation: boolean;
}

function ViewBase({ children, hideNavigation }: IViewBaseProps) {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const isSmallerScreen = useMediaQuery("(max-width: 960px)");

  const router = useRouter();
  const is404Page = router.pathname === "/404";
  const isMaintenancePage = router.pathname === "/maintenance";

  return (
    <div className="w-full h-full flex flex-col font-inter z-10">
      <Header
        drawe={!isSmallerScreen}
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
        hideNavigation={hideNavigation || isMaintenancePage}
        hideLogin={isMaintenancePage}
      />
      <div className={`p-0 ${isSmallerScreen ? "ml-0" : ""}`}>{children}</div>
      {!is404Page && !isMaintenancePage && <Footer />}
    </div>
  );
}

export default ViewBase;
