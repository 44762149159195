import React, { useEffect } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import "react-circular-progressbar/dist/styles.css";
import { Provider, useSelector } from "react-redux";
import TagManager from "react-gtm-module";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { appWithTranslation } from "next-i18next";
import { WagmiConfig } from "wagmi";
import wagmiConfig from "../lib/wagmi";
import ViewBase from "../components/ViewBase";
import { LoginContextProvider } from "../hooks";
import { IReduxState } from "../store/slices/state.interface";
import "../styles/globals.css";
import { storeWrapper } from "../store/store";
import CookiesAcceptance from "../components/CookiesAcceptance";
import isProduction from "../helpers/isProduction";

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const currentPage = useSelector<IReduxState, string>(
    (state) => state.app.currentpage
  );

  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <ViewBase
      hideNavigation={!!pageProps.privateQuest || !!pageProps.privateClassroom}
    >
      <Head>
        <title>{pageProps?.metas?.title || currentPage}</title>
        <meta
          itemProp="name"
          content={pageProps?.metas?.title || currentPage}
        />
        <meta
          itemProp="description"
          content={pageProps?.metas?.description || ""}
        />
        <meta itemProp="image" content={pageProps?.metas?.image?.url || ""} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={pageProps?.metas?.title || currentPage}
        />
        <meta
          name="twitter:description"
          content={pageProps?.metas?.description || ""}
        />
        <meta
          name="twitter:image"
          content={pageProps?.metas?.image?.url || ""}
        />
        <meta name="twitter:site" content="@Ledger" />

        <meta property="og:url" content={router.pathname} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={pageProps?.metas?.title || currentPage}
        />
        <meta
          property="og:description"
          content={pageProps?.metas?.description || ""}
        />
        <meta property="og:locale" content="en_US" />

        <meta
          property="og:image"
          content={pageProps?.metas?.image?.url || ""}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/Favicon-16x16.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/Favicon-32x32.png"
        />
      </Head>

      {isProduction ? <CookiesAcceptance /> : null}

      <Component {...pageProps} />
    </ViewBase>
  );
}

function AppWrapper({ Component, pageProps: { ...pageProps } }) {
  const { store } = storeWrapper.useWrappedStore(pageProps);

  return (
    <Provider store={store}>
      <WagmiConfig config={wagmiConfig}>
        <LoginContextProvider>
          <MyApp Component={Component} pageProps={pageProps} />
        </LoginContextProvider>
      </WagmiConfig>
    </Provider>
  );
}

export default appWithTranslation(AppWrapper);
