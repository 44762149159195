import { ContractTransaction as ContractTransactionType, ethers } from "ethers";
import { SignatureLike } from "@ethersproject/bytes";
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { LedgerQuestContract } from "../abi";
import { getGasPrice } from "./get-gas-price";
import logger from "./logger";

type SendMintTx = (
  signature: SignatureLike,
  owner: string,
  categoryId: number,
  collectionId: string
) => Promise<ContractTransactionType>;

export const increaseGasPrice = (gasPriceBase10: number) =>
  Math.round(gasPriceBase10 * 1.1);

export const callMintFunction: SendMintTx = async (
  signature,
  owner,
  categoryId,
  collectionId
) => {
  // Get the gas price so the txn isn't underpriced
  /*
    Move the gas price fn out of the try block
    to make it available in the catch block
  */
  const provider = new StaticJsonRpcProvider(
    process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_NETWORK_URI
  );
  const gasPrice = await getGasPrice(provider);
  const base10GasPrice = parseInt(gasPrice._hex, 16);
  logger.log("info", "[mint]", {
    mintMsg: "Gas price received",
    gasPrice: base10GasPrice,
  });
  // Create a signer using Ledger EOA private key. Attached the provider fresly create to the signer
  // @dev: Ledger EOA private key is only accessible backend-side. Returns undefined otherwise.
  const signer = new ethers.Wallet(process.env.LEDGER_EOA_PRIV_KEY, provider);

  // Create a contract instance using the contract ABI and the signer
  const ledgerNFTContract = new ethers.Contract(
    process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_QUEST_CONTRACT,
    LedgerQuestContract,
    signer
  );
  try {
    logger.log("info", "[mint]", {
      mintMsg: "Try mit",
      signature,
      owner,
      categoryId,
      collectionId,
      gasPrice: base10GasPrice,
    });
    const nonce = await provider.getTransactionCount(signer.address, "latest");
    const txOptions = {
      gasLimit: ethers.utils.hexlify(250000), // You might need to adjust this value based on the complexity of the mint function
      gasPrice: ethers.utils.parseUnits("500", "gwei"),
      nonce: nonce,
    };

    // Send the transaction to the contract
    return ledgerNFTContract.mint(
      signature,
      owner,
      categoryId.toString(),
      collectionId,
      txOptions
    );
  } catch (e) {
    logger.log("info", "[mint error]", {
      e,
    });
    if (
      e.message.includes(
        `Transaction gasPrice (${base10GasPrice}) is too low for the next block`
      )
    ) {
      logger.log("info", "[mint]", {
        mintMsg: "Replay Mint",
        signature,
        owner,
        categoryId,
        collectionId,
        gasPrice: base10GasPrice,
      });
      const increasedGasPrice = increaseGasPrice(base10GasPrice);
      logger.log("info", "[replayMint]", { withGas: increasedGasPrice });
      // Replay the mint
      try {
        return ledgerNFTContract.mint(
          signature,
          owner,
          categoryId.toString(),
          collectionId,
          { gasPrice: increasedGasPrice }
        );
      } catch (error) {
        logger.log("error", "[mintRetry]", { mintMsg: e.message });
        throw e;
      }
    }
    logger.log("error", "[mint]", { mintMsg: e.message });
    throw e;
  }
};

export const hasUserCompletedQuest = async (address, collectionId) => {
  try {
    const userCompletedQuestIds = [];
    const provider = new StaticJsonRpcProvider(
      process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_NETWORK_URI
    );

    // Create a signer using Ledger EOA private key. Attached the provider fresly create to the signer
    // @dev: Ledger EOA private key is only accessible backend-side. Returns undefined otherwise.
    const signer = new ethers.Wallet(process.env.LEDGER_EOA_PRIV_KEY, provider);

    // Create a contract instance using the contract ABI and the signer
    const ledgerNFTContract = new ethers.Contract(
      process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_QUEST_CONTRACT,
      LedgerQuestContract,
      signer
    );

    const tokenIdsOfOwner = await ledgerNFTContract.walletOfOwner(address);

    await Promise.all(
      tokenIdsOfOwner?.map(async (id) => {
        const categoryId = Math.floor(id / 10 ** 10);
        const collectionId = Math.floor(id / 10 ** 6) - categoryId * 10000;
        userCompletedQuestIds.push(Number(collectionId));
      })
    );

    return userCompletedQuestIds.includes(collectionId);
  } catch (e) {
    logger.log("error", "[walletOfOwner]", { bcCallMsg: e.message });
    throw e;
  }
};
