interface ButtonLoginProps {
  imgUrl: string;
  text: string;
  action: () => void;
}

function ButtonLogin(props: ButtonLoginProps) {
  const { imgUrl, text, action } = props;

  return (
    <button
      type="button"
      className="w-full box-border shrink-0 grow-0 basis-auto flex flex-row gap-6 items-center py-4 px-6 h-16 bg-gray-800 hover:bg-black border-[1px] border-solid border-gray-700 rounded"
      onClick={action}
      data-cy="Metamask"
    >
      <img className="w-8" src={imgUrl} alt="login" />
      <div className="bodyText">{text}</div>
    </button>
  );
}

export default ButtonLogin;
