import React from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import ProfileButton from "./ProfileButton";

interface ConnectedProps {
  closeMobileMenu: () => void;
}

const Connected = ({ closeMobileMenu }: ConnectedProps) => {
  const { t } = useTranslation("common");
  const router = useRouter();

  const handleClick = () => {
    closeMobileMenu();
    router.push("/profile/hub");
  };

  return (
    <div className="flex gap-4">
      <button
        type="button"
        className="flex gap-2 items-center py-1 px-3 captionText text-green bg-green-dark rounded"
        onClick={handleClick}
      >
        <img
          src="/icons/check-circle-green-outlined.svg"
          alt="connected icon"
          className="w-4 h-4"
        />
        <div>{t("connectButton.connectedShort")}</div>
      </button>
      <ProfileButton closeMobileMenu={closeMobileMenu} />
    </div>
  );
};

export default Connected;
