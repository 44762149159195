import React, { useEffect, useState } from "react";
import { Connector } from "wagmi";

interface WalletIconProps {
  connector: Connector;
  className?: string;
}

const WalletIcon = ({ connector, className }: WalletIconProps) => {
  const [iconSrc, setIconSrc] = useState<string>("");

  useEffect(() => {
    if (!connector) {
      setIconSrc("");
      return;
    }
    switch (connector.id) {
      case "metaMask":
        setIconSrc("/icons/MetaMask-Logo.svg");
        break;
      case "ledger":
        setIconSrc("/icons/ledger_logo_mobile.svg");
        break;
      case "injected":
        if (connector.name.toLowerCase().includes("brave")) {
          setIconSrc("/icons/brave.svg");
        } else if (connector.name.toLowerCase().includes("coinbase")) {
          setIconSrc("/icons/coinbase.png");
        } else {
          setIconSrc("");
        }
        break;
      default:
        setIconSrc("");
        break;
    }
  }, [connector]);

  if (!iconSrc) return null;

  return <img src={iconSrc} alt="wallet icon" className={className} />;
};

export default WalletIcon;
