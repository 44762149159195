export const domain = {
  name: process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_NAME,
  version: process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_VERSION,
  chainId: Number(process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_CHAINID),
  verifyingContract: process.env
    .NEXT_PUBLIC_TYPEDDATADOMAIN_QUEST_CONTRACT as `0x${string}`,
} as const;

export const types = {
  mint: [
    { name: "receiver", type: "address" },
    { name: "categoryId", type: "uint256" },
    { name: "collectionId", type: "uint256" },
  ],
} as const;
