interface SpinnerProps {
  size?: "small" | "medium" | "large";
  color?: string;
  bgColor?: string;
}

const Spinner = ({
  size = "medium",
  color = "purple",
  bgColor = "black",
}: SpinnerProps) => {
  let sizeClass: string;
  let colorClass: string;
  let bgColorClass: string;
  let gradientColorClass: string;

  switch (size) {
    case "small":
      sizeClass = "w-6 h-6";
      break;
    case "medium":
      sizeClass = "w-10 h-10";
      break;
    case "large":
      sizeClass = "w-14 h-14";
      break;
    default:
      sizeClass = "w-10 h-10";
  }
  switch (color) {
    case "purple":
      colorClass = "from-purple via-purple";
      break;
    case "darkPurple":
      colorClass = "from-purple-dark via-purple-dark";
      break;
    case "darkerPurple":
      colorClass = "from-purple-darker via-purple-darker";
      break;
    // Add other spinner colors here
    default:
      colorClass = "from-purple to-purple";
  }
  switch (bgColor) {
    case "black":
      bgColorClass = "bg-black";
      gradientColorClass = "to-black";
      break;
    case "white":
      bgColorClass = "bg-white";
      gradientColorClass = "to-white";
      break;
    case "purple":
      bgColorClass = "bg-purple-dark";
      gradientColorClass = "to-purple-dark";
      break;
    case "primary":
      bgColorClass = "bg-primary";
      gradientColorClass = "to-primary";
      break;
    default:
      bgColorClass = "bg-black";
      gradientColorClass = "to-black";
  }

  const gradientColors = `${colorClass} ${gradientColorClass}`;

  return (
    <div
      className={`animate-spin rounded-full ${sizeClass} mx-auto p-1 bg-[conic-gradient(at_bottom_right,_var(--tw-gradient-stops))] ${gradientColors}`}
    >
      <div
        className={`flex flex-col justify-between h-full ${bgColorClass} rounded-full`}
      />
    </div>
  );
};

export default Spinner;
