import { useEffect, useState } from "react";
import {
  AutorenewRounded,
  CheckCircleOutline,
  WarningOutlined,
} from "@material-ui/icons";
import { useTranslation } from "next-i18next";
import Button from "../Button";
import StatusModal from "../StatusModal";
import Spinner from "../Spinner";
import { useLoginContext } from "../../hooks/web3login/login-context";

interface LoginTrackingModalProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  isConnectError: string | null;
  isSignedIn: boolean;
  isOnWrongNetwork: boolean;
  isSigning: boolean;
}

function LoginTrackingModal(props: LoginTrackingModalProps) {
  const {
    modalIsOpen,
    closeModal,
    isConnectError,
    isSignedIn,
    isOnWrongNetwork,
    isSigning,
  } = props;
  const { t } = useTranslation("common");
  const { retry } = useLoginContext();
  const [signatureSuccess, setSignatureSuccess] = useState<boolean>(false);
  const [modalStatusIcon, setModalStatusIcon] = useState<JSX.Element>(
    <Spinner />
  );
  const [modalStatusButton, setModalStatusButton] = useState<boolean>(true);
  const [modalStatusTitle, setModalStatusTitle] = useState<string>(
    t("loginInProgress.title")
  );
  const [modalStatusMessage, setModalStatusMessage] = useState<string>(
    t("loginInProgress.description")
  );

  const resetStatusModal = () => {
    setModalStatusIcon(<Spinner />);
    setModalStatusTitle(t("loginInProgress.title"));
    setModalStatusMessage(t("loginInProgress.description"));
    setModalStatusButton(true);
    setSignatureSuccess(false);
  };

  useEffect(() => {
    if (isSignedIn) {
      setModalStatusTitle(t("success.title"));
      setModalStatusMessage(null);
      setModalStatusIcon(<CheckCircleOutline fontSize="large" />);
      setModalStatusButton(false);
      setSignatureSuccess(true);
      setTimeout(() => closeModal(), 1000); // Close modal after one second
    } else if (isOnWrongNetwork && !isConnectError) {
      setModalStatusTitle(t("switchNetwork.title"));
      setModalStatusMessage(t("switchNetwork.description"));
      setModalStatusIcon(<Spinner />);
      setSignatureSuccess(false);
    } else if (isSigning && !isConnectError) {
      setModalStatusTitle(t("signatureInProgress.title"));
      setModalStatusMessage(t("signatureInProgress.description"));
      setModalStatusIcon(<Spinner />);
      setSignatureSuccess(false);
    } else if (isConnectError) {
      setModalStatusTitle(t("error.title"));
      setModalStatusMessage(t("error.description"));
      setSignatureSuccess(false);
      setModalStatusIcon(<WarningOutlined fontSize="large" />);
    } else {
      resetStatusModal();
    }
  }, [isOnWrongNetwork, isSigning, isSignedIn, isConnectError]);

  const tryAgain = () => {
    if (!isSigning || isOnWrongNetwork || isConnectError) {
      retry();
      resetStatusModal();
    } else setModalStatusMessage(t("signatureInProgress.requestAlreadySent"));
  };

  return (
    <StatusModal
      isOpen={modalIsOpen}
      close={closeModal}
      error={!!isConnectError}
      title={modalStatusTitle}
      message={isConnectError || modalStatusMessage}
      button={
        modalStatusButton && (
          <Button
            label={t("loginInProgress.retry")}
            jsxIcon={<AutorenewRounded />}
            type="withWhiteBorder"
            onClick={tryAgain}
          />
        )
      }
      icon={modalStatusIcon}
      success={signatureSuccess}
    />
  );
}

export default LoginTrackingModal;
