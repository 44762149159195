import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, useMediaQuery } from "@material-ui/core";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import { IReduxState } from "../../store/slices/state.interface";
import ConnectButton from "./connect-button";
import Login from "../Login";
import { useLoginContext } from "../../hooks";

interface IHeader {
  drawe: boolean;
  mobileOpen: boolean;
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hideNavigation: boolean;
  hideLogin: boolean;
}

Modal.setAppElement("body");

function Header({
  drawe,
  mobileOpen,
  setMobileOpen,
  hideNavigation,
  hideLogin,
}: IHeader) {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { isReadyToQuest } = useLoginContext();
  const currentPage = useSelector<IReduxState, string>(
    (state) => state.app.currentpage
  );
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);

  const isMd: boolean = useMediaQuery("(max-width: 768px)");

  const showModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    /* 
      We can use safely 'querySelector' on the tag 'html' as the tag is outside the react/next code.
      This tag is not optimized by react and is not re-rendered when the state changes.
    */
    const html = document.querySelector("html");
    if (html && mobileOpen && isMd) {
      html.style.overflow = "hidden";
      window.scrollTo(0, 0);
    } else if (html) {
      html.style.overflow = "auto";
    }
  }, [mobileOpen, isMd]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isReadyToQuest) {
      timer = setTimeout(() => {
        setMobileOpen(false);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isReadyToQuest]);

  return (
    <div className="absolute w-full">
      {currentPage !== "Quizz" ? (
        <>
          <div
            className={`max-w-[1440px] mx-auto z-10 ${!drawe ? "ml-0" : ""}`}
          >
            <AppBar
              position="sticky"
              className="py-[10px] md:pt-5 md:pb-[30px] align-center border-b md:border-none !bg-transparent"
              elevation={0}
            >
              <Toolbar
                disableGutters
                className="md:mx-8 lg:mx-[112px] items-center flex justify-between md:pl-0 pl-4 md:pr-0 pr-4"
              >
                <button
                  type="button"
                  onClick={() => router.push("/")}
                  className="hidden cursor-pointer md:block"
                >
                  <img
                    style={{ width: "165.6px" }}
                    src="/images/ledger-quest-logo.png"
                    alt=""
                    className="w-36"
                    data-cy="LedgerLogo"
                  />
                </button>
                <button
                  type="button"
                  onClick={() => router.push("/")}
                  className="block cursor-pointer md:hidden"
                >
                  <img
                    src="/icons/ledger_logo_mobile.svg"
                    alt=""
                    className=""
                  />
                </button>
                <div className="items-center hidden md:flex ml-4">
                  <button
                    type="button"
                    onClick={() => router.push("/")}
                    className={`mr-12 text-white no-underline cursor-pointer navigation ${
                      hideNavigation ? "hidden" : ""
                    }`}
                    data-cy="Home"
                  >
                    {t("pages.home")}
                  </button>
                  <button
                    type="button"
                    onClick={() => router.push("/academy")}
                    className={`mr-12 text-white no-underline cursor-pointer navigation ${
                      hideNavigation ? "hidden" : ""
                    }`}
                    data-cy="Academy"
                  >
                    {t("pages.academy")}
                  </button>
                  <button
                    type="button"
                    onClick={() => router.push("/quests")}
                    className={`mr-12 text-white no-underline cursor-pointer navigation ${
                      hideNavigation ? "hidden" : ""
                    }`}
                    data-cy="Quests"
                  >
                    {t("pages.quests")}
                  </button>
                  <button
                    type="button"
                    onClick={() => router.push("/faq")}
                    className={`mr-12 text-white no-underline cursor-pointer navigation ${
                      hideNavigation ? "hidden" : ""
                    }`}
                    data-cy="FAQ"
                  >
                    {t("pages.faq")}
                  </button>
                  <div
                    className={`flex items-center ${hideLogin ? "hidden" : ""}`}
                  >
                    <ConnectButton
                      handleLogin={showModal}
                      closeMobileMenu={() => null}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="block w-6 h-6 md:hidden"
                  onClick={() => setMobileOpen(!mobileOpen)}
                >
                  {!mobileOpen ? <MenuIcon /> : <CloseIcon />}
                </button>
              </Toolbar>
            </AppBar>
          </div>
          {mobileOpen && isMd && (
            <div className="transition-all w-full h-full pt-5 pb-5 pl-4 pr-4 bg-black fixed z-20 flex flex-col text-white captionText gap-5">
              <button
                type="button"
                className="text-left"
                onClick={() => {
                  router.push("/");
                  setMobileOpen(false);
                }}
              >
                {t("pages.home")}
              </button>
              <button
                type="button"
                className="text-left"
                onClick={() => {
                  router.push("/academy");
                  setMobileOpen(false);
                }}
              >
                {t("pages.academy")}
              </button>
              <button
                type="button"
                className="text-left"
                onClick={() => {
                  router.push("/quests");
                  setMobileOpen(false);
                }}
              >
                {t("pages.quests")}
              </button>
              <button
                type="button"
                className="text-left"
                onClick={() => {
                  router.push("/faq");
                  setMobileOpen(false);
                }}
              >
                {t("pages.faq")}
              </button>

              <ConnectButton
                handleLogin={showModal}
                closeMobileMenu={() => setMobileOpen(false)}
              />
            </div>
          )}
        </>
      ) : null}
      <Login
        isOpen={modalIsOpen}
        close={closeModal}
        redirectToProfile={!hideNavigation}
      />
    </div>
  );
}

export default Header;
