import { configureStore } from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";
import type {} from "redux-thunk/extend-redux";
import accountReducer from "./slices/account-slice";
import appReducer from "./slices/app-slice";
import pendingTransactionsReducer from "./slices/pending-txns-slice";

const store: any = () =>
  configureStore({
    reducer: {
      account: accountReducer,
      app: appReducer,
      pendingTransactions: pendingTransactionsReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
  });

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const storeWrapper = createWrapper(store);

export default store;
