import React from "react";
import { useTranslation } from "next-i18next";

interface WrongNetworkMessageProps {
  className?: string;
}

const WrongNetworkMessage = ({ className }: WrongNetworkMessageProps) => {
  const { t } = useTranslation("common");

  return (
    <div className={className}>
      <div className="relative w-fit whitespace-nowrap text-left text-white bg-gray-700 rounded py-2 px-3 z-10">
        <p>{t("disclaimer.title")}</p>
        <p>{t("disclaimer.subtitle")}</p>
      </div>
      <div className="absolute w-6 h-6 -top-3 left-1/2 -translate-x-1/2 rotate-45 bg-gray-700" />
    </div>
  );
};

export default WrongNetworkMessage;
