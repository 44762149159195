import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "next-i18next";
import React from "react";
import { twMerge } from "tailwind-merge";
import { useLoginContext } from "../../../hooks";

interface INotConnectedProps {
  handleLogin: () => void;
  stretch?: boolean;
}

interface ButtonIconProps {
  isLoading: boolean;
}

const ButtonIcon = ({ isLoading }: ButtonIconProps) => {
  if (isLoading) {
    return <CircularProgress size={20} />;
  }
  return <img src="/icons/Wallet.svg" alt="wallet icon" />;
};

const NotConnected = ({ handleLogin, stretch }: INotConnectedProps) => {
  const { t } = useTranslation("common");
  const { isOnWrongNetwork, isProcessing } = useLoginContext();

  return (
    <button
      type="button"
      className={twMerge(
        `bg-white shadow-custom-shadow rounded-full pl-6 pr-10 py-4 flex items-center text-black gap-8 md:w-auto w-[276px] h-12 md:justify-start justify-between`,
        stretch ? "w-full" : ""
      )}
      onClick={handleLogin}
    >
      <p
        className="captionText text-black whitespace-nowrap"
        data-cy="ConnectButton"
      >
        {t("connectButton.connectWallet")}
      </p>
      <ButtonIcon isLoading={isProcessing && !isOnWrongNetwork} />
    </button>
  );
};

export default NotConnected;
