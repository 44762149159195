import React, { useRef, useState } from "react";
import ProfileMenu from "./ProfileMenu";

interface ProfileButtonProps {
  closeMobileMenu: () => void;
}

const ProfileButton = ({ closeMobileMenu }: ProfileButtonProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <button
        type="button"
        onClick={() => setIsMenuOpen((prev) => !prev)}
        className="group relative flex gap-2 items-center bg-transparent"
        ref={buttonRef}
      >
        <img
          src="/icons/profile.svg"
          alt="profile icon"
          className="w-6 h-6 transition-left"
        />
        {isMenuOpen ? (
          <img
            src="/icons/Dropup.svg"
            alt="dropup"
            className="w-3 h-3 transition-right"
          />
        ) : (
          <img
            src="/icons/Dropdown.svg"
            alt="dropdown"
            className="w-3 h-3 transition-right"
          />
        )}
      </button>
      {isMenuOpen ? (
        <ProfileMenu
          closeMenu={() => setIsMenuOpen(false)}
          closeMobileMainMenu={closeMobileMenu}
          buttonRef={buttonRef}
          className="absolute right-0 top-14"
        />
      ) : null}
    </>
  );
};

export default ProfileButton;
