import Script from "next/script";

function CookiesAcceptance() {
  return (
    <>
      {/* OneTrust Cookies Consent Notice start for quest.ledger.com */}
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script="55200e61-0647-4bf2-ba76-01d49292acd9"
      />
      <Script
        id="cookies-acceptance"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `function OptanonWrapper() {}`,
        }}
      />
      {/* OneTrust Cookies Consent Notice end for quest.ledger.com */}
    </>
  );
}

export default CookiesAcceptance;
