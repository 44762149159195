import { IPresentationQuest } from "./interfaces";
import { locales } from "./locales";

export type Locale = keyof typeof locales;

export type LocaleMap = { locale: Locale; name: string };

export type QuestCTAStatusType =
  | "LOADING"
  | "ERROR"
  | "MINT_ERROR"
  | "MINT_PROGRESS"
  | "CLAIM_CONNECT"
  | "CLAIM_MISSING"
  | "CLAIM_GET"
  | "MINT_COMPLETED"
  | "ENDED"
  | "COMING_SOON"
  | "CONNECT_WALLET"
  | "MISSING_TOKEN"
  | "START_QUEST_TG"
  | "START_QUEST";

export type TokenRequirementsType =
  | "LOADING"
  | "PASSED"
  | "NOT_REQUIRED_TOKENS"
  | "NOT_ENOUGH_TOKENS"
  | "ERROR";

export type TokenType = "ERC20" | "ERC721" | "ERC1155";

export type ResultsStatusType =
  | "IS_BOT"
  | "LOADING"
  | "FAILURE"
  | "NOT_MINTED"
  | "MINTING";

export type ConnectButtonStatusType =
  | "NOT_CONNECTED"
  | "CONNECTED"
  | "CONNECTED_POK"
  | "WRONG_NETWORK";

export type EnvType = "prd" | "prx" | "sbx" | "stg" | "dev";

export type TransactionStatusType =
  | "LOADING"
  | "UNKNOWN"
  | "NO_TRANSACTION"
  | "PENDING"
  | "SUCCESS"
  | "ERROR";

export const apiRequests = {
  EXISTS: "EXISTS",
  SELECT: "SELECT",
  INSERT: "INSERT",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
};

export type APIRequestType = keyof typeof apiRequests;

export const MoreQuestsStatus = {
  NOT_COMPLETED_OPENED_END_DATE: 1,
  NOT_COMPLETED_OPENED_NO_END_DATE: 2,
  NOT_COMPLETED_TG_NO_END_DATE: 3,
  NOT_COMPLETED_TG_END_DATE: 4,
  COMING_SOON: 5,
  COMPLETED_CLAIM: 6,
  NOT_COMPLETED_CLAIM: 7,
  COMPLETED_OPENED_END_DATE: 8,
  COMPLETED_OPENED_NO_END_DATE: 9,
  COMPLETED_TG_NO_END_DATE: 10,
  COMPLETED_TG_END_DATE: 11,
  ENDED: 12,
  NOT_REQUIRED_TOKENS: 13,
};

export type MoreQuestsStatusType = number;

export const HomeQuestsWithoutConnexionStatus = {
  OPENED_END_DATE: 1,
  OPENED_NO_END_DATE: 2,
  TG_NO_END_DATE: 3,
  TG_END_DATE: 4,
  COMING_SOON: 5,
  CLAIM: 6,
  ENDED: 7,
};

export type HomeQuestsWithoutConnexionStatusType = number;

export type MoreQuestsResultType =
  | { ok: false; error: string; data: null }
  | { ok: true; error: null; data: IPresentationQuest[] };

export type MintDataType = {
  receiver: `0x${string}`;
  categoryId: number;
  collectionId: number;
  nonce: any;
};

export type QuestStatusType =
  | "COMING_SOON"
  | "AVAILABLE"
  | "DAYS_LEFT"
  | "LAST_DAY"
  | "ENDED";

export type PictureType = "image" | "video";

export type ClassroomCTAStatusType =
  | "LOADING"
  | "CONNECT_WALLET"
  | "START_NEXT"
  | "COMPLETED";

export type AvailableQuestsResultType =
  | { ok: false; error: string; data: null }
  | { ok: true; error: null; data: number[] };

export type ProfileNavigationType = "hub" | "collection";

export type ProfilePokNavigationType = "earned" | "available";

export type ClassroomPageType = "Academy page" | "Partner page";
