const formatServerUrl = (url: string): string => {
  const isServer = typeof window === "undefined";
  const PORT = process.env.PORT || 3000;
  const questOriginUrl = `http://localhost:${PORT}`;

  if (isServer) {
    return `${questOriginUrl}${url}`;
  }
  return url;
};

export default formatServerUrl;
