import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { useEthersProvider } from "../lib/adapters/ethers";
import { LedgerQuestContract } from "../abi";
import { useLoginContext } from "./web3login";
import logger from "../helpers/logger";

export default function useUserStatus() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAllowanceLoading, setIsAllowanceLoading] = useState<boolean>(true);
  const [isCompletedQuestsLoading, setIsCompletedQuestsLoading] =
    useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [isAllowanceError, setIsAllowanceError] = useState<boolean>(false);
  const [isCompletedQuestsError, setIsCompletedQuestsError] =
    useState<boolean>(false);
  const [error, setError] = useState<string[]>([]);
  const [userIsAllowed, setUserIsAllowed] = useState<boolean>(false);
  const [userCompletedQuests, setUserCompletedQuests] = useState<number[]>([]);
  const [userCompletedTokenIds, setUserCompletedTokenIds] = useState<
    { collectionId: number; tokenId: string }[]
  >([]);
  const [contract, setContract] = useState<ethers.Contract>(null);
  const { isReadyToQuest } = useLoginContext();
  const { address } = useAccount();
  const provider = useEthersProvider();

  useEffect(() => {
    setIsError(isAllowanceError || isCompletedQuestsError);
  }, [isAllowanceError, isCompletedQuestsError]);

  const reset = () => {
    setError([]);
    setIsAllowanceError(false);
    setIsCompletedQuestsError(false);
  };

  useEffect(() => {
    setIsLoading(isAllowanceLoading || isCompletedQuestsLoading);
  }, [isAllowanceLoading, isCompletedQuestsLoading]);

  const checkIfUserIsAllowed = () => {
    setIsAllowanceLoading(true);
    setIsAllowanceError(false);
    if (Number(process.env.NEXT_PUBLIC_RESTRICTED_MODE_ENABLED) === 0) {
      setUserIsAllowed(true);
      setIsAllowanceLoading(false);
    } else {
      try {
        contract.allowlist(address).then((allowed) => {
          setUserIsAllowed(allowed);
        });
      } catch (e) {
        logger.log("error", "[userStatusIsAllowed]", { bcCallMsg: e.message });
        setIsAllowanceError(true);
        setError([...error, "Error checking user allowed status"]);
      } finally {
        setIsAllowanceLoading(false);
      }
    }
  };

  const getUserCompletedQuests = () => {
    setIsCompletedQuestsLoading(true);
    setIsCompletedQuestsError(false);
    const userCompletedQuestIds = [];
    const userCompletedQuestTokenIds = [];
    try {
      contract.walletOfOwner(address).then((tokenIdsOfOwner) => {
        tokenIdsOfOwner?.forEach((id) => {
          const categoryId = Math.floor(id / 10 ** 10);
          const collectionId = Math.floor(id / 10 ** 6) - categoryId * 10000;
          userCompletedQuestIds.push(Number(collectionId));
          userCompletedQuestTokenIds.push({
            collectionId: Number(collectionId),
            tokenId: Number(id._hex).toString(),
          });
        });
        setUserCompletedQuests(userCompletedQuestIds);
        setUserCompletedTokenIds(userCompletedQuestTokenIds);
      });
    } catch (e) {
      logger.log("error", "[userStatusWalletOfOwner]", {
        bcCallMsg: e.message,
      });
      setIsCompletedQuestsError(true);
      setError([...error, "Error getting user completed quests"]);
    } finally {
      setIsCompletedQuestsLoading(false);
    }
  };

  useEffect(() => {
    if (!isReadyToQuest) {
      setUserIsAllowed(false);
      setUserCompletedQuests([]);
      setContract(null);
      setIsAllowanceLoading(false);
      setIsCompletedQuestsLoading(false);
      setIsError(false);
      setError([]);
    } else {
      const ledgerNFTContract = new ethers.Contract(
        process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_QUEST_CONTRACT,
        LedgerQuestContract,
        provider
      );
      setContract(ledgerNFTContract);
    }
  }, [isReadyToQuest]);

  useEffect(() => {
    if (contract) {
      checkIfUserIsAllowed();
      getUserCompletedQuests();
    }
  }, [contract]);

  const refresh = () => {
    reset();
    if (contract) {
      checkIfUserIsAllowed();
      getUserCompletedQuests();
    }
  };

  useEffect(() => {
    window.addEventListener("focus", refresh);
    return () => window.removeEventListener("focus", refresh);
  }, []);

  return {
    isLoading,
    isError,
    error,
    userIsAllowed,
    userCompletedQuests,
    refresh,
    userCompletedTokenIds,
  };
}
