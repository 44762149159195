import {
  IQuestion,
  ICompanionQuote,
  IUserQuest,
  IMetadata,
  ITokenGate,
  IAntiCheat,
} from "../interfaces";
// eslint-disable-next-line import/no-cycle
import { getCategoryId } from "./utils";
import {
  Locale,
  apiRequests,
  MoreQuestsResultType,
  AvailableQuestsResultType,
  ClassroomPageType,
} from "../src/types";
import {
  IDetailsQuest,
  IHomePage,
  IMoreQuest,
  IPresentationQuest,
  IQuestResult,
  IQuestsPage,
  IQuizz,
  IImage,
  IClassroom,
  IClassroomQuest,
  IMaintenance,
  IWhatsNext,
  ILink,
  IAvailableQuest,
  IProfilePage,
  IClassroomPage,
} from "../src/interfaces";
import formatServerUrl from "./formatServerUrl";
import logger from "./logger";

const contentfulAddress = `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT}`;

const fetchContentful = async (query: string) => {
  const response = await fetch(contentfulAddress, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: process.env.CONTENTFUL_CPA_KEY,
    },
    body: JSON.stringify({
      query: `
      {
        ${query}
      }
      `,
    }),
  });

  if (response) {
    const resData = await response.json();
    if (response.status >= 400) {
      logger.log("error", "[fetchContentful]", {
        errorMessage: resData.errors[0].message,
        status: response.status,
      });
    }

    const { data } = resData;
    if (data !== undefined && data[Object.keys(data)[0]].items.length > 0) {
      return data;
    }
  }

  return null;
};

const presentationQuestQuery = () => `
  sys {
    id
  }
  title
  slug
  questPicture {
    url
    contentType
  }
  startDate
  endDate
  categoryId
  collectionId
  partnerName
  tokenGateListCollection {
    items {
      sys {
        id
      }
    }
  }
  privateQuest
`;

const getPictureType = (contentTypeReceived) => {
  const pictureTypeReceived: string =
    contentTypeReceived?.toString() || "image";
  return pictureTypeReceived.includes("image") ? "image" : "video";
};

const questsDataToPresentationQuests = (
  presQuests: any[]
): IPresentationQuest[] => {
  const result: IPresentationQuest[] = [];

  presQuests.forEach((quest) => {
    const tokenGateList = quest.tokenGateListCollection?.items;
    const tokenGated = tokenGateList ? tokenGateList.length > 0 : false;

    result.push({
      id: quest.sys.id,
      title: quest.title,
      slug: quest.slug,
      questPicture: {
        url: quest.questPicture?.url.toString() || null,
        type: getPictureType(quest.questPicture?.contentType),
      },
      startDate: quest.startDate,
      endDate: quest.endDate,
      categoryId: quest.categoryId,
      collectionId: quest.collectionId,
      tokenGated: tokenGated,
      partnerName: quest.partnerName,
      completed: null,
    });
  });

  return result;
};

export const getMetadata = async (
  slug: string,
  locale: Locale
): Promise<IMetadata> => {
  const data = await fetchContentful(
    `
    metadataCollection(
      where: { slug: "${slug}" }
      limit: 1
      locale: "${locale}"
    ) {
      items {
        title
        description
        image {
          title
          url
        }
      }
    }
    `
  );
  return data?.metadataCollection?.items[0] || null;
};

export const getMoreQuests = async (
  locale: Locale,
  idToAvoid: string
): Promise<IMoreQuest[]> => {
  const data = await fetchContentful(
    `
    questCollection(where: { privateQuest: false, sys: {id_not: "${idToAvoid}"} } locale: "${locale}") {
      items {
        sys {
          id
        }
        title
        slug
        questPicture {
          url
          contentType
        }
        startDate
        endDate
        categoryId
        collectionId
        partnerName
        tokenGateListCollection {
          items {
            sys {
              id
            }
            tokenGateName
            tokenGateAddress
            tokenGateChainId
            tokenType
            erc20MinimumNeeded
          }
        }
        privateQuest
        claimStatusActive
        difficultyLevel
      }
    }
    `
  );
  if (data === null) return null;

  const result: IMoreQuest[] = [];
  const moreQuests = data.questCollection?.items;

  moreQuests.forEach((quest) => {
    const tokenGateList = quest.tokenGateListCollection?.items || [];
    const tokenGated = tokenGateList ? tokenGateList.length > 0 : false;

    result.push({
      id: quest.sys.id,
      title: quest.title,
      slug: quest.slug,
      questPicture: {
        url: quest.questPicture?.url.toString() || null,
        type: getPictureType(quest.questPicture?.contentType),
      },
      startDate: quest.startDate,
      endDate: quest.endDate,
      categoryId: quest.categoryId,
      collectionId: quest.collectionId,
      tokenGated: tokenGated,
      tokenGateList: tokenGateList,
      partnerName: quest.partnerName,
      claimStatus: quest.claimStatusActive || false,
      difficultyLevel: quest.difficultyLevel,
      completed: null,
    });
  });

  return result;
};

export const getSuggestedQuests = async (
  locale: Locale
): Promise<IMoreQuest[]> => {
  const data = await fetchContentful(
    `
    questCollection(where: { privateQuest: false } locale: "${locale}") {
      items {
        sys {
          id
        }
        title
        slug
        questPicture {
          url
          contentType
        }
        startDate
        endDate
        categoryId
        collectionId
        partnerName
        tokenGateListCollection {
          items {
            sys {
              id
            }
            tokenGateName
            tokenGateAddress
            tokenGateChainId
            tokenType
            erc20MinimumNeeded
          }
        }
        privateQuest
        claimStatusActive
        difficultyLevel
      }
    }
    `
  );
  if (data === null) return null;

  const result: IMoreQuest[] = [];
  const moreQuests = data.questCollection?.items;

  moreQuests.forEach((quest) => {
    const tokenGateList = quest.tokenGateListCollection?.items || [];
    const tokenGated = tokenGateList ? tokenGateList.length > 0 : false;

    result.push({
      id: quest.sys.id,
      title: quest.title,
      slug: quest.slug,
      questPicture: {
        url: quest.questPicture?.url.toString() || null,
        type: getPictureType(quest.questPicture?.contentType),
      },
      startDate: quest.startDate,
      endDate: quest.endDate,
      categoryId: quest.categoryId,
      collectionId: quest.collectionId,
      tokenGated: tokenGated,
      tokenGateList: tokenGateList,
      partnerName: quest.partnerName,
      claimStatus: quest.claimStatusActive || false,
      difficultyLevel: quest.difficultyLevel,
      completed: null,
    });
  });

  return result;
};

export const getAllHomeQuests = async (
  locale: Locale
): Promise<IMoreQuest[]> => {
  const data = await fetchContentful(
    `
    questCollection(where: { privateQuest: false } locale: "${locale}") {
      items {
        sys {
          id
        }
        title
        slug
        questPicture {
          url
          contentType
        }
        startDate
        endDate
        categoryId
        collectionId
        partnerName
        tokenGateListCollection {
          items {
            sys {
              id
            }
            tokenGateName
            tokenGateAddress
            tokenGateChainId
            tokenType
            erc20MinimumNeeded
          }
        }
        privateQuest
        claimStatusActive
        difficultyLevel
      }
    }
    `
  );
  if (data === null) return null;

  const result: IMoreQuest[] = [];
  const homeQuests = data.questCollection?.items;

  homeQuests.forEach((quest) => {
    const tokenGateList = quest.tokenGateListCollection?.items || [];
    const tokenGated = tokenGateList ? tokenGateList.length > 0 : false;

    result.push({
      id: quest.sys.id,
      title: quest.title,
      slug: quest.slug,
      questPicture: {
        url: quest.questPicture?.url.toString() || null,
        type: getPictureType(quest.questPicture?.contentType),
      },
      startDate: quest.startDate,
      endDate: quest.endDate,
      categoryId: quest.categoryId,
      collectionId: quest.collectionId,
      tokenGated: tokenGated,
      tokenGateList: tokenGateList,
      partnerName: quest.partnerName,
      claimStatus: quest.claimStatusActive || false,
      difficultyLevel: quest.difficultyLevel,
      completed: null,
    });
  });

  return result;
};

export const getAllPublicQuests = async (
  locale: Locale
): Promise<IPresentationQuest[]> => {
  const data = await fetchContentful(
    `
    questCollection(where: { privateQuest: false } locale: "${locale}") {
      items {
        ${presentationQuestQuery()}
      }
    }
    `
  );
  if (data === null) return null;
  return questsDataToPresentationQuests(data.questCollection.items);
};

export const getAllQuests = async (
  locale: Locale
): Promise<IPresentationQuest[]> => {
  const data = await fetchContentful(
    `
    questCollection(locale: "${locale}") {
      items {
        ${presentationQuestQuery()}
      }
    }
    `
  );
  if (data === null) return null;
  return questsDataToPresentationQuests(data.questCollection.items);
};

export const getClassroomQuestsByClassroomSlug = async (
  locale: Locale,
  slug: string
): Promise<IPresentationQuest[]> => {
  const data = await fetchContentful(
    `
    classroomCollection(where: { slug: "${slug}" }, limit: 1, locale: "${locale}") {
      items {
        questsCollection(locale: "${locale}") {
          items {
            ${presentationQuestQuery()}
          }
        }
      }
    }
    `
  );
  if (data === null) return null;

  return questsDataToPresentationQuests(
    data.classroomCollection.items[0].questsCollection.items
  );
};

export const getHomePage = async (locale: Locale): Promise<IHomePage> => {
  const data = await fetchContentful(
    `
    questPageCollection(limit: 1, locale: "${locale}") {
      items {
        sliderQuestsCollection {
          items {
            ${presentationQuestQuery()}
            sys {
              id
            }
          }
        }
        showBanner
        bannerQuest {
          slug
          shortDescription
          partnerName
          homepageBanner {
            url
            title
            width
            height
          }
        }
      }
    }
    `
  );
  if (data === null) return null;
  const homePageData = data.questPageCollection.items[0];

  const sliderQuestsItems = homePageData.sliderQuestsCollection?.items;
  const sliderQuests =
    sliderQuestsItems && sliderQuestsItems.length > 0 ? sliderQuestsItems : [];

  const homePage: IHomePage = {
    sliderQuests: questsDataToPresentationQuests(
      sliderQuests.filter((q) => !q.privateQuest)
    ),
    showComingSoon: homePageData.showBanner && homePageData.bannerQuest,
    comingSoonQuest: {
      slug: homePageData?.bannerQuest?.slug || "",
      description: homePageData?.bannerQuest?.shortDescription || "",
      partnerName: homePageData?.bannerQuest?.partnerName || "",
      imageUrl: homePageData?.bannerQuest?.homepageBanner?.url || "",
    },
  };

  return homePage;
};

export const getQuestsPage = async (locale: Locale): Promise<IQuestsPage> => {
  const data = await fetchContentful(
    `
    questsPageCollection(limit: 1, locale: "${locale}") {
      items {
        heroTitle
        heroDescription
      }
    }
    `
  );
  if (data === null) return null;

  const questsPageData = data.questsPageCollection.items[0];
  const questsPage: IQuestsPage = {
    heroTitle: questsPageData.heroTitle,
    heroDescription: questsPageData.heroDescription,
  };
  return questsPage;
};

const getQuestionsCount = (
  questionsCountField,
  questQuestionsCount,
  tokenGated
) => {
  const isQuestQuestionCountLimited =
    questionsCountField !== null &&
    questionsCountField > 0 &&
    questionsCountField < questQuestionsCount;

  if (isQuestQuestionCountLimited && !tokenGated) {
    return questionsCountField;
  }
  return questQuestionsCount;
};

const getQuestionsCountToSucceed = (leniency, questionsCount) =>
  leniency && questionsCount > 10
    ? Math.round(questionsCount * 0.9)
    : questionsCount;

export const getDetailsQuest = async (
  locale: Locale,
  slug: string
): Promise<IDetailsQuest> => {
  const data = await fetchContentful(
    `
    questCollection(where: { slug: "${slug}" }, limit: 1, locale: "${locale}") {
      items {
        title
        slug
        questPicture {
          url
          contentType
        }
        shortDescription
        difficultyLevel
        startDate
        endDate
        categoryId
        customCategoryLabel
        collectionId
        tokenGateListCollection {
          items {
            sys {
              id
            }
            tokenGateName
            tokenGateAddress
            tokenGateChainId
            tokenType
            erc20MinimumNeeded
            avatar {
              url
            }
            openSeaUrl
            raribleUrl
            etherscanUrl
          }
        }
        feat {
          url
          height
          width
        }
        partnerName
        banner {
          url
        }
        questDetailTitle
        discoverUrl
        privateQuest
        flagCollection
        knowledgeFlag
        questionsCount
        questionsCollection {
          items {
            sys {
              id
            }
          }
        }
        collectionCollection {
          items {
            sys {
              id
            }
            title
            description
            picture {
              url
            }
          }
        }
        topicCollection {
          items {
            sys {
              id
            }
            title
            picture {
              url
            }
            articleCollection {
              items {
                sys {
                  id
                }
                title
                category
                articleUrl
              }
            }
          }
        }
        claimStatusActive
        claimReward
        claimUrl
        metadata {
          title
          description
          image {
            title
            url
          }
        }
      }
    }
    `
  );
  if (data === null) return null;

  const detailsQuestDataPart1 = data.questCollection.items[0];

  const dataPart2 = await fetchContentful(
    `
    questCollection(where: { slug: "${slug}" }, limit: 1, locale: "${locale}") {
      items {
        knowledgeDescription {
          json
          links {
            entries {
              inline {
                sys {
                  id
                }
                __typename
              }
            }
            assets {
              block {
                __typename
                sys {
                  id
                }
                fileName
                url
                title
                width
                height
                description
              }
            }
          }
        }
      }
    }
    `
  );

  if (dataPart2 === null) return null;

  const detailsQuestDataPart2 = dataPart2.questCollection.items[0];

  const tokenGateList = detailsQuestDataPart1.tokenGateListCollection?.items;
  const tokenGated = tokenGateList ? tokenGateList.length > 0 : false;

  const questionsCount = getQuestionsCount(
    detailsQuestDataPart1.questionsCount,
    detailsQuestDataPart1.questionsCollection.items?.length || 0,
    tokenGated
  );

  const estimatedTime = Math.round(questionsCount * 0.5);

  let featImage: IImage | null = null;
  if (detailsQuestDataPart1.feat) {
    featImage = {
      url: detailsQuestDataPart1.feat.url,
      height: detailsQuestDataPart1.feat.height,
      width: detailsQuestDataPart1.feat.width,
    };
  }

  const detailsQuest: IDetailsQuest = {
    title: detailsQuestDataPart1.title,
    slug: detailsQuestDataPart1.slug,
    startDate: detailsQuestDataPart1.startDate,
    endDate: detailsQuestDataPart1.endDate,
    questPicture: {
      url: detailsQuestDataPart1.questPicture?.url.toString() || null,
      type: getPictureType(detailsQuestDataPart1.questPicture?.contentType),
    },
    categoryId: detailsQuestDataPart1.categoryId,
    collectionId: detailsQuestDataPart1.collectionId,
    difficultyLevel: detailsQuestDataPart1.difficultyLevel,
    estimatedTime: estimatedTime,
    customCategoryLabel: detailsQuestDataPart1.customCategoryLabel,
    shortDescription: detailsQuestDataPart1.shortDescription,
    feat: featImage,
    bannerUrl: detailsQuestDataPart1.banner?.url.toString() || null,
    discoverUrl: detailsQuestDataPart1.discoverUrl,
    questDetailTitle: detailsQuestDataPart1.questDetailTitle,
    tokenGated: tokenGated,
    tokenGateList: tokenGateList,
    partnerName: detailsQuestDataPart1.partnerName,
    claimStatusFlag: detailsQuestDataPart1.claimStatusActive,
    claimUrl: detailsQuestDataPart1.claimUrl,
    claimReward: detailsQuestDataPart1.claimReward,
    knowledgeFlag: detailsQuestDataPart1.knowledgeFlag,
    knowledgeDescription: detailsQuestDataPart2.knowledgeDescription,
    topics: detailsQuestDataPart1.topicCollection?.items || null,
    privateQuest: detailsQuestDataPart1.privateQuest,
    collectionFlag: detailsQuestDataPart1.flagCollection,
    collection: detailsQuestDataPart1.collectionCollection?.items || null,
    metadata: detailsQuestDataPart1.metadata,
  };
  return detailsQuest;
};

export const getQuizz = async (
  locale: Locale,
  slug: string
): Promise<IQuizz> => {
  const data = await fetchContentful(
    `
    questCollection(
      where: { slug: "${slug}" }
      limit: 1
      locale: "${locale}"
    ) {
      items {
        sys {
          id
        }
        title
        questPicture {
          url
          contentType
        }
        startDate
        endDate
        difficultyLevel
        categoryId
        collectionId
        tokenGateListCollection {
          items {
            sys {
              id
            }
          }
        }
        questionsCount
        leniency
        questionsCollection {
          items {
            sys {
              id
            }
          }
        }
      }
    }
    `
  );

  const quizzData = data?.questCollection?.items[0];
  if (quizzData === undefined) return null;

  const tokenGateList = quizzData?.tokenGateListCollection?.items;
  const tokenGated = tokenGateList ? tokenGateList.length > 0 : false;

  const questionsCount = getQuestionsCount(
    quizzData.questionsCount,
    quizzData.questionsCollection.items?.length || 0,
    tokenGated
  );

  const questionsCountToSucceed = getQuestionsCountToSucceed(
    quizzData.leniency || true,
    questionsCount
  );

  const quizz: IQuizz = {
    id: quizzData.sys.id,
    title: quizzData.title,
    slug: slug,
    questPicture: {
      url: quizzData.questPicture?.url.toString() || null,
      type: getPictureType(quizzData.questPicture?.contentType),
    },
    startDate: quizzData.startDate,
    endDate: quizzData.endDate,
    difficultyLevel: quizzData.difficultyLevel,
    categoryId: quizzData.categoryId,
    collectionId: quizzData.collectionId,
    questionsCount: questionsCount,
    questionsCountToSucceed: questionsCountToSucceed,
    questions: quizzData.questionsCollection?.items || null,
    tokenGated: tokenGated,
  };
  return quizz;
};

export const getAntiCheatValues = async (
  locale: Locale
): Promise<IAntiCheat> => {
  const data = await fetchContentful(
    `
    antiCheatCollection(
      limit: 1
      locale: "${locale}"
    ) {
      items {
        sys {
          id
        }
        minimumTimePerQuestion
        valueToCalcMinTimeToAnswerAllQuestions 
      }
    }
    `
  );
  return data?.antiCheatCollection?.items[0] || null;
};

export const getTokenGateList = async (id: string): Promise<ITokenGate[]> => {
  const data = await fetchContentful(
    `
    questCollection(
      where: { sys: { id: "${id}" } }
      limit: 1
    ) {
      items {
        tokenGateListCollection {
          items {
            sys {
              id
            }
            tokenGateName
            tokenGateAddress
            tokenGateChainId
            tokenType
            erc20MinimumNeeded
            avatar {
              url
            }
            openSeaUrl
            raribleUrl
            etherscanUrl
          }
        }
      }
    }
    `
  );

  const getTokenGateListData = data?.questCollection?.items[0];
  if (getTokenGateListData === undefined) return null;

  return getTokenGateListData.tokenGateListCollection.items;
};

export const getQuestionsByIds: (
  arrayIds: string[],
  locale: Locale
) => Promise<IQuestion[]> = async (
  arrayIds: string[],
  locale: Locale
): Promise<IQuestion[]> => {
  const ids: string = arrayIds.join('","');
  const limit: number = arrayIds.length;

  const query = `
  questionCollection(
      where: {
        sys: { id_in: ["${ids}"] }
      },
      limit: ${limit},
      locale: "${locale}"
    ) {
      items {
        sys {
          id
        }
        text
        media {
          url
          contentType
        }
        answersCollection {
          items {
            sys {
              id
            }
            text
          }
        }
        articlesCollection {
          items {
            sys {
              id
            }
            title
            category
            articleUrl
          }
        }
      }
    }
  `;

  const data = await fetchContentful(query);
  return data?.questionCollection?.items || null;
};

export const getQuestResultById = async (
  id,
  locale: Locale
): Promise<IQuestResult> => {
  const data = await fetchContentful(
    `
    questCollection(
      where: { sys: { id: "${id}" } }
      limit: 1
      locale: "${locale}"
    ) {
      items {
        tokenGateListCollection(limit: 1) {
          items {
            sys {
              id
            }
          }
        }
        questionsCount
        leniency
        questionsCollection(limit: 100) {
          items {
            sys {
              id
            }
            answersCollection(limit: 10) {
              items {
                sys {
                  id
                }
                isCorrect
              }
            }
          }
        }
      }
    }
    `
  );

  const questResultData = data?.questCollection?.items[0];
  if (questResultData === undefined) return null;

  const tokenGateList = questResultData.tokenGateListCollection?.items;
  const tokenGated = tokenGateList ? tokenGateList.length > 0 : false;

  const questionsCount = getQuestionsCount(
    questResultData.questionsCount,
    questResultData.questionsCollection.items?.length || 0,
    tokenGated
  );

  const questionsCountToSucceed = getQuestionsCountToSucceed(
    questResultData.leniency || true,
    questionsCount
  );

  const result: IQuestResult = {
    tokenGated: tokenGated,
    questionsCount: questionsCount,
    questionsCountToSucceed: questionsCountToSucceed,
    questions: questResultData.questionsCollection?.items || null,
  };
  return result;
};

export const getQuestionsByQuestSlug = async (
  locale: Locale,
  slug: string
): Promise<IQuestion[]> => {
  const data = await fetchContentful(
    `
    questCollection(
      where: { slug: "${slug}" }
      limit: 1
      locale: "${locale}"
    ) {
      items {
        questionsCollection(limit: 100) {
          items {
            sys {
              id
            }
            text
            articlesCollection {
              items {
                sys {
                  id
                }
                title
                category
                articleUrl
              }
            }
          }
        }
      }
    }
    `
  );
  return data?.questCollection?.items[0]?.questionsCollection?.items || null;
};

export const getAllQuotes = async (
  locale: Locale
): Promise<ICompanionQuote[]> => {
  const data = await fetchContentful(
    `
    quotesCollection(locale: "${locale}") {
      items {
        text
        parameter {
          state
          text
          priority
        }
      }
    }
    `
  );
  return data?.quotesCollection?.items || null;
};

export const getCategoryIdCollectionIdById = async (
  id,
  locale: Locale
): Promise<any> => {
  const data = await fetchContentful(
    `
    questCollection(
      where: { sys: { id: "${id}" } }
      limit: 1
      locale: "${locale}"
    ) {
      items {
        categoryId
        collectionId
      }
    }
    `
  );
  if (data === null) return null;

  const quest = data.questCollection.items[0];

  return {
    categoryId: getCategoryId(quest.categoryId),
    collectionId: quest.collectionId,
  };
};

export const getIsOnMaintenance = async (): Promise<boolean> => {
  const data = await fetchContentful(
    `
    questPageCollection(limit: 1, locale: "en") {
      items {
        onMaintenance
      }
    }
    `
  );
  if (data === null) return null;

  const maintenanceData = data.questPageCollection.items[0];

  return maintenanceData.onMaintenance || false;
};

export const getMaintenance = async (locale: Locale): Promise<IMaintenance> => {
  const data = await fetchContentful(
    `
    questPageCollection(limit: 1, locale: "${locale}") {
      items {
        onMaintenance
        maintenanceTitle
        maintenanceSubtitle
        maintenanceDescription
        maintenanceCtaLabel
        maintenanceCtaUrl
      }
    }
    `
  );
  if (data === null) return null;

  const maintenanceData = data.questPageCollection.items[0];

  const onMaintenance = maintenanceData.onMaintenance || false;

  const maintenance: IMaintenance = {
    isOnMaintenance: onMaintenance,
    title: onMaintenance ? maintenanceData.maintenanceTitle : null,
    subtitle: onMaintenance ? maintenanceData.maintenanceSubtitle : null,
    description: onMaintenance ? maintenanceData.maintenanceDescription : null,
    ctaLabel: onMaintenance ? maintenanceData.maintenanceCtaLabel : null,
    ctaUrl: onMaintenance ? maintenanceData.maintenanceCtaUrl : null,
  };

  return maintenance;
};

const getWhatsNext = (classroom: any): IWhatsNext[] => {
  const whatsNext: IWhatsNext[] = [];
  if (
    classroom.whatsNext1Title &&
    classroom.whatsNext1LinkLabel &&
    classroom.whatsNext1LinkUrl
  ) {
    const link: ILink = {
      label: classroom.whatsNext1LinkLabel,
      url: classroom.whatsNext1LinkUrl,
    };
    whatsNext.push({
      title: classroom.whatsNext1Title,
      bannerUrl: classroom.whatsNext1Banner?.url.toString() || "",
      link: link,
    });
  }

  if (
    classroom.whatsNext2Title &&
    classroom.whatsNext2LinkLabel &&
    classroom.whatsNext2LinkUrl
  ) {
    const link: ILink = {
      label: classroom.whatsNext2LinkLabel,
      url: classroom.whatsNext2LinkUrl,
    };
    whatsNext.push({
      title: classroom.whatsNext2Title,
      bannerUrl: classroom.whatsNext2Banner?.url.toString() || "",
      link: link,
    });
  }
  if (
    classroom.whatsNext3Title &&
    classroom.whatsNext3LinkLabel &&
    classroom.whatsNext3LinkUrl
  ) {
    const link: ILink = {
      label: classroom.whatsNext3LinkLabel,
      url: classroom.whatsNext3LinkUrl,
    };
    whatsNext.push({
      title: classroom.whatsNext3Title,
      bannerUrl: classroom.whatsNext3Banner?.url.toString() || "",
      link: link,
    });
  }
  if (
    classroom.whatsNext4Title &&
    classroom.whatsNext4LinkLabel &&
    classroom.whatsNext4LinkUrl
  ) {
    const link: ILink = {
      label: classroom.whatsNext4LinkLabel,
      url: classroom.whatsNext4LinkUrl,
    };
    whatsNext.push({
      title: classroom.whatsNext4Title,
      bannerUrl: classroom.whatsNext4Banner?.url.toString() || "",
      link: link,
    });
  }
  return whatsNext;
};

const classroomDataToClassroom = (classroom: any): IClassroom => {
  if (classroom === null) return null;

  const questsIdsData = classroom.questsCollection?.items || [];
  const quests: IClassroomQuest[] = [];
  questsIdsData.forEach((qid) => {
    quests.push({
      id: qid.sys.id,
      slug: qid.slug || "",
      collectionId: qid.collectionId,
    });
  });
  const questsTitle = classroom.privateClassroom
    ? classroom.questsTitle?.toString() || ""
    : "";

  let academy: ILink = null;
  if (classroom.academyCtaLabel && classroom.academyUrl) {
    academy = {
      label: classroom.academyCtaLabel,
      url: classroom.academyUrl,
    };
  }

  return {
    title: classroom.title,
    slug: classroom.slug,
    isPrivate: classroom.privateClassroom || false,
    subtitle: classroom.subtitle,
    horizontalBannerUrl: classroom.banner?.url.toString() || "",
    verticalBannerUrl: classroom.verticalBanner?.url.toString() || "",
    description: classroom.description,
    difficulty: classroom.difficulty,
    topic: classroom.topic,
    academy: academy,
    questsTitle: questsTitle,
    quests: quests,
    estimatedTime: 0,
    whatsNext: getWhatsNext(classroom),
    completed: null,
  };
};

const classroomsDataToClassrooms = (presClassrooms: any[]): IClassroom[] => {
  const result: IClassroom[] = [];

  presClassrooms.forEach((classroom) => {
    result.push(classroomDataToClassroom(classroom));
  });

  return result;
};

export const getClassroomById = async (
  locale: Locale,
  slug: string
): Promise<IClassroom> => {
  const data = await fetchContentful(
    `
    classroomCollection(where: { slug: "${slug}" }, limit: 1, locale: "${locale}") {
      items {
        title
        slug
        subtitle
        banner {
          url
        }
        verticalBanner {
          url
        }
        description
        difficulty
        topic
        academyUrl
        questsCollection(limit: 50) {
          items {
            sys {
              id
            }
            slug
            collectionId
            questionsCount
            questionsCollection {
              total
            }
            tokenGateListCollection {
              total
            }
          }
        }
        whatsNext1Title
        whatsNext1LinkLabel
        whatsNext1LinkUrl
        whatsNext1Banner {
          url
        }
        whatsNext2Title
        whatsNext2LinkLabel
        whatsNext2LinkUrl
        whatsNext2Banner {
          url
        }
        whatsNext3Title
        whatsNext3LinkLabel
        whatsNext3LinkUrl
        whatsNext3Banner {
          url
        }
        whatsNext4Title
        whatsNext4LinkLabel
        whatsNext4LinkUrl
        whatsNext4Banner {
          url
        }
      }
    }
    `
  );
  if (data === null) return null;

  const classroomData = data.classroomCollection.items[0];

  let estimatedTime = 0;

  classroomData.questsCollection.items.forEach((quest) => {
    const tokenGated = quest.tokenGateListCollection.total > 0;
    const questionsCount = getQuestionsCount(
      quest.questionsCount,
      quest.questionsCollection.total || 0,
      tokenGated
    );

    estimatedTime += questionsCount * 0.5;
  });

  const roundedEstimatedTime = Math.round(estimatedTime);

  const classroom: IClassroom = {
    ...classroomDataToClassroom(classroomData),
    estimatedTime: roundedEstimatedTime,
  };

  return classroom;
};

export const getClassroomPage = async (
  locale: Locale,
  type: ClassroomPageType
): Promise<IClassroomPage> => {
  const data = await fetchContentful(
    `
    academyPageCollection(where: { title: "${type}" }, limit: 1, locale: "${locale}") {
      items {
        title
        heroTitle
        heroDescription
        heroSubtitle
        classroomsCollection {
          items {
            title
            slug
            privateClassroom
            subtitle
            banner {
              url
            }
            verticalBanner {
              url
            }
            description
            difficulty
            topic
            academyCtaLabel
            academyUrl
            questsTitle
            questsCollection {
              items {
                sys {
                  id
                }
                slug
                collectionId
              }
            }
          }
        }
      }
    }
    `
  );

  if (data === null) return null;

  const classroomPageData = data.academyPageCollection.items[0];
  const classroomPage: IClassroomPage = {
    heroTitle: classroomPageData.heroTitle || "",
    heroDescription: classroomPageData.heroDescription || "",
    heroSubtitle: classroomPageData.heroSubtitle || "",
    classrooms: classroomsDataToClassrooms(
      classroomPageData.classroomsCollection.items.filter(
        (classroom) => !classroom.privateClassroom
      )
    ),
  };
  return classroomPage;
};

export const getClassroomsContainingQuestSlug = async (
  locale: Locale,
  slug: string
): Promise<IClassroom[]> => {
  const data = await fetchContentful(
    `
    academyPageCollection(limit: 1, locale: "${locale}") {
      items {
        heroTitle
        heroDescription
        heroSubtitle
        classroomsCollection {
          items {
            title
            slug
            privateClassroom
            subtitle
            banner {
              url
            }
            verticalBanner {
              url
            }
            description
            difficulty
            topic
            academyUrl
            questsCollection {
              items {
                sys {
                  id
                }
                slug
                collectionId
              }
            }
          }
        }
      }
    }
    `
  );

  if (data === null) return null;

  const academyPageData = data.academyPageCollection.items[0];

  const allClassrooms = classroomsDataToClassrooms(
    academyPageData.classroomsCollection.items
  );

  const filteredClassrooms: IClassroom[] = allClassrooms.filter((classroom) => {
    let containingQuest = false;

    classroom.quests.forEach((quest) => {
      if (quest.slug === slug) {
        containingQuest = true;
      }
    });

    return containingQuest;
  });

  return filteredClassrooms;
};

export const getIsPrivateClassroom = async (
  locale: Locale,
  slug: string
): Promise<IClassroom> => {
  const data = await fetchContentful(
    `
    classroomCollection(where: { slug: "${slug}" }, limit: 1, locale: "${locale}") {
      items {
        privateClassroom
      }
    }
    `
  );
  if (data === null) return null;

  const classroomData = data.classroomCollection.items[0];

  return classroomData.privateClassroom;
};

export const getIsAcademyClassroom = async (
  locale: Locale,
  type: ClassroomPageType,
  slug: string
): Promise<IClassroom> => {
  const data = await fetchContentful(
    `
    academyPageCollection(where: { title: "${type}" }, limit: 1, locale: "${locale}") {
      items {
        classroomsCollection {
          items {
            slug
          }
        }
      }
    }
    `
  );
  if (data === null) return null;

  const classroomPageData = data.academyPageCollection.items[0];

  const classroomsSlug = classroomPageData.classroomsCollection.items.map(
    (classroom) => classroom.slug
  );

  return classroomsSlug.includes(slug);
};

export const getAvailableQuests = async (
  locale: Locale
): Promise<IAvailableQuest[]> => {
  const data = await fetchContentful(
    `
    questCollection(where: { privateQuest: false } locale: "${locale}") {
      items {
        sys {
          id
        }
        startDate
        endDate
        collectionId
      }
    }
    `
  );
  if (data === null) return null;

  const result: IAvailableQuest[] = [];
  const availableQuests = data.questCollection?.items;

  availableQuests.forEach((quest) => {
    result.push({
      id: quest.sys.id,
      startDate: quest.startDate,
      endDate: quest.endDate,
      collectionId: quest.collectionId,
    });
  });

  return result;
};

export const getProfilePage = async (locale: Locale): Promise<IProfilePage> => {
  const data = await fetchContentful(
    `
    profilePageCollection(limit: 1, locale: "${locale}") {
      items {
        whatsNext1Title
        whatsNext1LinkLabel
        whatsNext1LinkUrl
        whatsNext1Banner {
          url
        }
        whatsNext2Title
        whatsNext2LinkLabel
        whatsNext2LinkUrl
        whatsNext2Banner {
          url
        }
        whatsNext3Title
        whatsNext3LinkLabel
        whatsNext3LinkUrl
        whatsNext3Banner {
          url
        }
        whatsNext4Title
        whatsNext4LinkLabel
        whatsNext4LinkUrl
        whatsNext4Banner {
          url
        }
      }
    }
    `
  );

  if (data === null) return null;

  const profilePageData = data.profilePageCollection.items[0];
  const profilePage: IProfilePage = {
    whatsNext: getWhatsNext(profilePageData),
  };
  return profilePage;
};

const requestOptions = {
  method: "POST",
  headers: { "Content-Type": "application/json" },
};

type ProcessUserResultsType = (
  userResults: IUserQuest,
  address: string
) => Promise<any>;
export const processUserResults: ProcessUserResultsType = async (
  userResults,
  address
) => {
  try {
    const serverUrl = formatServerUrl(`/api/userResults`);
    const response = await fetch(serverUrl, {
      ...requestOptions,
      body: JSON.stringify({
        userResults: JSON.stringify(userResults),
        address: address,
      }),
    });
    return response.json();
  } catch (e) {
    console.error(
      `An error occurred while fetching user results: ${e.message}`
    );
    throw new Error("Failed to fetch user results. Please try again later.");
  }
};

export const getUser = async (walletaddress) => {
  try {
    const serverUrl = formatServerUrl(
      `/api/user?walletaddress=${walletaddress}`
    );
    const response = await fetch(serverUrl, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return response.json();
  } catch (e) {
    console.error(`An error occurred while fetching transaction: ${e.message}`);
    throw new Error("Failed to fetch transaction. Please try again later.");
  }
};

export const getUsersNonce = async () => {
  const serverUrl = formatServerUrl(`/api/nonce`);
  const response = await fetch(serverUrl, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return response.json();
};

export const getTransaction = async (address, categoryId, collectionId) => {
  try {
    const serverUrl = formatServerUrl(`/api/transaction`);
    const response = await fetch(serverUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        address: address,
        categoryId: categoryId,
        collectionId: collectionId,
        request: apiRequests.SELECT,
      }),
    });
    return response.json();
  } catch (e) {
    console.error(`An error occurred while fetching transaction: ${e.message}`);
    throw new Error("Failed to fetch transaction. Please try again later.");
  }
};

export const deleteTransaction = async (
  address,
  categoryId,
  collectionId,
  nonce
) => {
  try {
    const serverUrl = formatServerUrl(`/api/transaction`);
    const response = await fetch(serverUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        address: address,
        categoryId: categoryId,
        collectionId: collectionId,
        nonce: nonce,
        request: apiRequests.DELETE,
      }),
    });
    return response.json();
  } catch (e) {
    console.error(`An error occurred while deleting transaction`);
    throw new Error("Failed to delete transaction. Please try again later.");
  }
};

// CONTRACT INTERACTIONS
const ROUTE = formatServerUrl("/api/mint");
type MintNFTType = (
  value: {
    receiver: string;
    categoryId: number;
    collectionId: number;
    nonce: string;
  },
  signature: string,
  gReCaptchaToken: string
) => Promise<any>;

export const mintNFT: MintNFTType = async (
  value,
  signature: string,
  gReCaptchaToken: string
) => {
  try {
    const response = await fetch(`${ROUTE}`, {
      ...requestOptions,
      body: JSON.stringify({
        value,
        signature: signature,
        gReCaptchaToken: gReCaptchaToken,
      }),
    });
    return response.json();
  } catch (e) {
    console.error(`An error occurred while fetching transaction: ${e.message}`);
    throw new Error("Failed to fetch transaction. Please try again later.");
  }
};

export const getThreeMoreQuests = async (
  address: `0x${string}`,
  currentQuestId: string
): Promise<MoreQuestsResultType> => {
  try {
    const serverUrl = formatServerUrl("/api/contentful/moreQuests");
    const response = await fetch(serverUrl, {
      ...requestOptions,
      body: JSON.stringify({
        address,
        currentQuestId,
      }),
    });
    return response.json();
  } catch (e) {
    throw new Error("Failed to fetch more quests.");
  }
};

export const getAvailableQuestsCollection = async (
  address: `0x${string}`
): Promise<AvailableQuestsResultType> => {
  try {
    const serverUrl = formatServerUrl("/api/contentful/availableQuests");
    const response = await fetch(serverUrl, {
      ...requestOptions,
      body: JSON.stringify({
        address,
      }),
    });
    return response.json();
  } catch (e) {
    throw new Error("Failed to fetch available quests.");
  }
};
